import React from "react";

// components
import { Table } from "antd";
import type { TableProps } from "antd";

// react router
import { useNavigate } from "react-router-dom";

// cointants and icons
import { FaEye } from "react-icons/fa6";

// types and service
import { useAppSelector } from "../../../store/hooks";
import { IProjecUser } from "../../../store/types/project";
import { format_date_time } from "../../../services/date/format";
// import { ITask } from "../../../store/types/task";
// import TimeFrame from "./TimeFrame";

const AvailableTasks: React.FC = () => {
  const navigate = useNavigate();
  const projects = useAppSelector(({ project }) => project.projects);

  const columns: TableProps<IProjecUser>["columns"] = [
    {
      title: "PROJECTS",
      dataIndex: "project",
      align: "center",
      render: (data) => (
        <span
          onClick={() => navigate(`/task/${data?.slug}`)}
          className="capitalize cursor-pointer"
        >
          {data?.name}
        </span>
      ),
    },
    {
      title: "Supervisor",
      dataIndex: "project",
      align: "center",
      render: (data) => {
        if (data?.supervisor)
          return (
            <span>{`${data?.supervisor?.profile?.first_name} ${data?.supervisor?.profile?.last_name}`}</span>
          );
        return <span>Not Assigned</span>;
      },
    },
    // This doesnt eve have a start and end date
    // {
    //   title: "TIME LEFT",
    //   dataIndex: "deadline",
    //   align: "center",
    //   render: (data) => <TimeLeft deadline={data?.[0]} />,
    // },
    {
      title: "Created By",
      dataIndex: "project",
      align: "center",
      render: (data) => {
        if (data?.created_by) {
          return (
            <span>{`${data?.created_by?.profile?.first_name} ${data?.created_by?.profile?.last_name}`}</span>
          );
        }
        return <span>Not Assinged</span>;
      },
    },
    {
      title: "Date Created",
      dataIndex: "project",
      align: "center",
      render: (data) => {
        if (data?.created_at) {
          return <span>{format_date_time(data.created_at)}</span>;
        }
        return <span>Not Assinged</span>;
      },
    },
    {
      title: "ACTION",
      dataIndex: "project",
      align: "center",
      render: (data) => (
        <div className="flex justify-center">
          <FaEye
            className="cursor-pointer mr-3"
            onClick={() => navigate(`/task/${data?.slug}`)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-abel mx-2 pl-12 md:pr-4 md:pl-4 overflow-x-scroll">
      <Table dataSource={projects} columns={columns} key="id" rowKey="id" />
    </div>
  );
};

export default AvailableTasks;
