import React from "react";

// components
import { Drawer } from "antd";
import TextInputBox from "../../../components/elements/forms/TextInputBox";
import TextInput from "../../../components/elements/forms/TextInput";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import moment from "moment";
import {
  ITaskDeadlineData,
  ITaskDeadlineError,
} from "../../../store/types/task_deadline";
import { create } from "../../../services/api/task_deadline";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

type IResetSubTask = {
  show: boolean;
  task_slug: string;
  onClose: () => void;
};

const ResetSubTask: React.FC<IResetSubTask> = ({
  show,
  task_slug,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ task_deadline }) => task_deadline.loading);
  const [data, dataSet] = React.useState<ITaskDeadlineData>({
    end_time: "",
    start_time: "",
    reason: "",
    task_slug: "",
  });

  const [error, errorSet] = React.useState<ITaskDeadlineError>({
    end_time: "",
    start_time: "",
  });

  const onValidate = () => {
    let validate: boolean = false;
    if (!data.end_time) {
      validate = true;
      errorSet((prev) => ({ ...prev, end_time: "warning" }));
    }
    if (!data.start_time) {
      validate = true;
      errorSet((prev) => ({ ...prev, start_time: "warning" }));
    }
    if (!data.task_slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, task_slug: "warning" }));
    }
    return validate ? false : true;
  };

  const onLoad = React.useCallback(() => {
    if (task_slug) dataSet((prev) => ({ ...prev, task_slug }));
  }, [task_slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Drawer title="Reset Subtask" width={500} onClose={onClose} open={show}>
      <div className="px-4 py-4">
        <p className="text-[25px] pb-5">RMS Project</p>
        <TextInputBox
          label="Reason for reset"
          placeholder="Enter text here..."
          status={""}
          name="reason"
          value={data.reason}
          onChange={(e) =>
            dataSet((prev) => ({ ...prev, reason: e.target.value }))
          }
        />
        <div className="flex md:justify-between flex-wrap">
          <TextInput
            label="New Start Date"
            placeholder="Enter Subject"
            status={error.start_time}
            name="start_time"
            value={data.start_time}
            type="datetime-local"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              dataSet((prev) => ({
                ...prev,
                [name]: moment(value).format("YYYY-MM-DD HH:mm:ss"),
              }));
              errorSet((prev) => ({ ...prev, [name]: "" }));
            }}
            container="mb-4 md:w-[49%] w-full"
          />
          <TextInput
            label="New End Date"
            placeholder="Enter Subject"
            status={error.end_time}
            name="end_time"
            value={data.end_time}
            type="datetime-local"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              dataSet((prev) => ({
                ...prev,
                [name]: moment(value).format("YYYY-MM-DD HH:mm:ss"),
              }));
              errorSet((prev) => ({ ...prev, [name]: "" }));
            }}
            container="mb-4 md:w-[49%] w-full"
          />
        </div>

        <div className="flex justify-between max-w-[376px] mx-auto pt-8">
          <SubmitButton
            title="Update Subtask"
            onSubmitHandler={() => {
              if (onValidate()) create(dispatch, data);
            }}
            loading={loading}
            className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ResetSubTask;
