import React from "react";
import { Link } from "react-router-dom";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import useLogin from "./hooks";
import TextInput from "../../../components/elements/forms/TextInput";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { auth } from "../../../services/api/auth";
import db from "../../../constants/database";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ auth }) => auth.loading);
  const { data, error, onChangeHandler, onValidate } = useLogin();

  return (
    <div className="bg-[#FAFBFF] font-abel flex justify-center items-center h-screen px-4 py-4">
      <div className="max-w-456 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-8 px-7 w-full">
          <Link to="#" className="flex justify-center mb-2">
            <img src={db.logo} alt="logo" className="w-[120px]" />
          </Link>
          <h1 className="text-[28px] text-center">Log In</h1>
          <p className="text-[#667185] text-16 text-center mb-5">
            Enter your credentials to access your account
          </p>
          <TextInput
            label="EMAIL ADDRESS"
            placeholder="Enter Email"
            status={error.email}
            name="email"
            value={data.email}
            onChange={onChangeHandler}
          />
          <TextInput
            label="PASSWORD"
            placeholder="Enter Password"
            status={error.password}
            name="password"
            type="password"
            value={data.password}
            onChange={onChangeHandler}
            container="my-3"
          />
          <div className="flex justify-between items-center mt-4 align-center">
            <label>
              <input
                type="checkbox"
                className="text-[#243677] checked:bg-blue-500"
              />{" "}
              Remember me
            </label>
            <Link to="/forgot-password" className="text-[#243677]">
              Forgot Password?
            </Link>
          </div>
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Log into Account"
                onSubmitHandler={() => {
                  const validate = onValidate();
                  if (validate) {
                    auth(data, dispatch);
                  }
                }}
                loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>

          {/* <div className="text-center mt-6">
            <span>
              Are you new here?{" "}
              <Link to="/register" className="text-[#243677]">
                {" "}
                Create Account
              </Link>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
