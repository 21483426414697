// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import { openNotification } from "../../components/ui/alerts";

// types and dispatcher and router
import { AppDispatch } from "../../store";
import {
  setDelete,
  setLoading,
  // setMyTasks,
  setTasks,
  setTotal,
  setTotalCompletedTask,
  setTotalFailedTask,
} from "../../store/slices/task";
import {
  IAssignUser,
  ICreateTask,
  IUpdateStatus,
} from "../../store/types/task";
import { NavigateFunction } from "react-router-dom";

export const get_all_tasks = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-all-tasks");

    dispatch(setTasks(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_number_of_tasks = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-total-tasks");

    dispatch(setTotal(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_number_of_completed_task = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-all-completed-tasks");

    dispatch(setTotalCompletedTask(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_number_of_failed_task = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-all-failed-tasks");

    dispatch(setTotalFailedTask(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

/**
 *
 * @param dispatch
 * @param slug
 */

export const get_tasks = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/tasks/${slug}`);

    dispatch(setTasks(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_task = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/task/${slug}`);

    dispatch(setTasks(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const create = async (
  dispatch: AppDispatch,
  data: ICreateTask,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/task", data);

    // dispatch(setTasks(response.data?.data));
    openNotification({ status: "success", message: response?.data?.message });
    navigate(`/task/${data.project_slug}`);
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const update_status = async (
  dispatch: AppDispatch,
  data: IUpdateStatus,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch("/task-status-update", data);

    // dispatch(setTasks(response.data?.data));
    openNotification({ status: "success", message: response?.data?.message });
    // get_tasks(dispatch, data.slug);
    navigate(-1); //reload page after submission
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const assing_task = async (dispatch: AppDispatch, data: IAssignUser) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch("/task-assign-user", data);

    openNotification({ status: "success", message: response?.data?.message });
    // get_tasks(dispatch, data.slug);
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const destroy = async (dispatch: AppDispatch, slug: string) => {
  try {
    const response = await axiosInstance.delete(`/task/${slug}`);

    dispatch(setDelete(response.data?.data?.slug));
    openNotification({
      status: "success",
      message: response.data?.message,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
};
