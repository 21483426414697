import up from "../assets/images/svg/icons/up.svg";
import down from "../assets/images/svg/icons/down.svg";
import logo from "../assets/images/png/task-manager-logo.png";

const db = {
  up: up,
  down: down,
  logo: logo,

  percent: [
    { id: 1, percentage: 0 },
    { id: 2, percentage: 20 },
    { id: 3, percentage: 40 },
    { id: 4, percentage: 60 },
    { id: 5, percentage: 80 },
    { id: 6, percentage: 100 },
  ],

  kpiData: [
    { id: 1, name: "John Doe", progressNumber: 50 },
    { id: 2, name: "John Doe", progressNumber: 35 },
    { id: 3, name: "John Doe", progressNumber: 80 },
    { id: 4, name: "John Doe", progressNumber: 100 },
    { id: 5, name: "John Doe", progressNumber: 70 },
    { id: 6, name: "John Doe", progressNumber: 20 },
    { id: 7, name: "John Doe", progressNumber: 50 },
    { id: 8, name: "John Doe", progressNumber: 35 },
    { id: 9, name: "John Doe", progressNumber: 80 },
    { id: 10, name: "John Doe", progressNumber: 95 },
    { id: 11, name: "John Doe", progressNumber: 70 },
    { id: 12, name: "John Doe", progressNumber: 20 },
  ],
};

export default db;
