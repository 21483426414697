import { configureStore } from "@reduxjs/toolkit";

// environment
import env from "../config/env";

// store
import user from "./slices/user";
import task from "./slices/task";
import auth from "./slices/auth";
import misc from "./slices/misc";
import kpi from "./slices/kpi";
import project from "./slices/project";
import setting from "./slices/setting";
import task_deadline from "./slices/task_deadline";

export const store = configureStore({
  reducer: {
    kpi,
    auth,
    user,
    misc,
    task,
    setting,
    project,
    task_deadline,
  },
  devTools: env.enviroment === "production" ? false : true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
