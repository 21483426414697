import React from "react";
import useCreateUser from "../hooks/useCreateUser";

// components
import CheckBox from "../../../components/elements/forms/CheckBox";
import TextInput from "../../../components/elements/forms/TextInput";
import SubmitButton from "../../../components/elements/forms/SubmitButton";

// types, hooks and service
import { get_organization } from "../../../services/api/auth";
import { create } from "../../../services/api/project_user";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";

const CreateUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(({ user }) => user.loading);

  const { data, error, dataSet, onChangeHandler, onValidate } = useCreateUser();

  const onLoad = React.useCallback(() => {
    get_organization(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel flex justify-center items-center h-[90%] px-4 py-4">
      <div className="max-w-456 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-7 px-7 w-full">
          <h1 className="text-24 font-bold text-black text-center">
            Create User
          </h1>
          <TextInput
            label="FirstName"
            placeholder="Enter First Name"
            status={error.first_name}
            name="first_name"
            value={data.first_name}
            onChange={onChangeHandler}
            container="mb-4"
          />
          <TextInput
            label="Last Name"
            placeholder="Enter Last Name"
            status={error.last_name}
            name="last_name"
            value={data.last_name}
            onChange={onChangeHandler}
            container="mb-4"
          />
          <TextInput
            label="Username"
            placeholder="Enter Username"
            status={error.username}
            name="username"
            value={data.username}
            onChange={onChangeHandler}
            container="mb-4"
          />
          <TextInput
            label="Email"
            placeholder="Enter Email"
            status={error.email}
            name="email"
            value={data.email}
            onChange={onChangeHandler}
            container="mb-4"
          />
          <CheckBox
            id="admin"
            name="admin"
            title="Is user an admin"
            checked={data.admin}
            onChange={() =>
              dataSet((prev) => ({ ...prev, admin: !data.admin }))
            }
          />

          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Create User"
                onSubmitHandler={() => {
                  if (onValidate() === true) create(dispatch, data, navigate);
                }}
                loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
