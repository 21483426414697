import React from "react";

// components
import { Drawer } from "antd";
import TimeFrame from "./TimeFrame";
import ResetSubTask from "./ResetSubTask";
import SetOwner from "../../subtask/misc/SetOwner";
import TimeLeft from "../../../components/ui/status/TimeLeft";
import TaskStatus from "../../../components/ui/status/TaskStatus";
import SubmitButton from "../../../components/elements/forms/SubmitButton";

// types and navigation
import { ITask } from "../../../store/types/task";
import { format_date_time } from "../../../services/date/format";
// import { format_date_time } from "../../../services/date/format";

type IViewSubTask = {
  show: boolean;
  task: ITask;
  onClose: () => void;
  onUpdateStatus?: (slug: string) => void;
  onAssignUser?: (slug: string) => void;
};

const ViewSubTask: React.FC<IViewSubTask> = ({
  task,
  show,
  onClose,
  onAssignUser,
  onUpdateStatus,
}) => {
  const [reset, resetSet] = React.useState<{ show: boolean; slug: string }>({
    show: false,
    slug: "",
  });

  return (
    <Drawer title="Task Details" width={500} onClose={onClose} open={show}>
      <div className="px-2 py-2">
        <p className="text-[18px] pb-5">{task?.name}</p>
        <div className="bg-[#F7F9FF] rounded-[27px] w-full p-6">
          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Task Owner</p>

            {task?.assigned ? (
              <p className="text-[#344054]">
                {`${task.assigned.profile.first_name || ""} ${
                  task.assigned.profile.last_name || ""
                }`}
              </p>
            ) : (
              <SetOwner on_edit={() => onAssignUser?.(task.slug)} />
            )}
          </div>

          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Task Description</p>
            <p className="text-[#344054]">{task.description}</p>
          </div>

          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Time Left</p>

            <TimeLeft start_time={task.start_time} end_time={task.end_time} />
          </div>
          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Time Frame</p>
            <TimeFrame end_time={task?.end_time} />
          </div>
          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Resets</p>
            {/* <p className="text-[#344054]">{3 - task.deadline?.length}</p> */}
          </div>
          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Start Date</p>
            <p className="text-[#344054]">
              {format_date_time(task.start_time)}
            </p>
          </div>
          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">End Date</p>
            <p className="text-[#344054]">{format_date_time(task.end_time)}</p>
          </div>
          <div className="flex justify-between text-[15px] border-b border-[#E6E6E7] pb-3 mb-3">
            <p className="text-[#797979]">Verified By</p>
            {/* <p className="text-[#344054]">{3 - task.deadline?.length}</p> */}
          </div>
          <div className="flex justify-between text-[15px]">
            <p className="text-[#797979]">Status</p>
            <TaskStatus status={task?.status} />
          </div>
        </div>

        <div className="flex justify-between max-w-[376px] mx-auto pt-8">
          <SubmitButton
            title="Update Status"
            onSubmitHandler={() => onUpdateStatus?.(task.slug)}
            // loading={loading}
            className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
          />
          <div className="w-[20px]"></div>
          <SubmitButton
            title="Reset Subtask"
            onSubmitHandler={() => resetSet({ show: true, slug: task.slug })}
            // loading={loading}
            className="bg-[white] border border-[#D0CFCF] font-[400] text-[black] md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
          />
        </div>

        {/* <div className="bg-[#F7F9FF] rounded-[27px] w-full p-4 my-4">
          {task.deadline.map((item, index) => (
            <div
              key={index + 1}
              className={`flex justify-between text-[15px] py-2 my-3 ${
                task.deadline.length !== index + 1 &&
                "border-b border-[#E6E6E7]"
              }`}
            >
              <p className="text-[#797979]">
                {item.status === 1 ? "ongoing" : "canceled"}
              </p>
              <p className="text-[#344054]">
                {`${item?.start_time && format_date_time(item.start_time)} - ${
                  item?.end_time && format_date_time(item.end_time)
                }`}
              </p>
            </div>
          ))}
        </div> */}

        <ResetSubTask
          show={reset.show}
          task_slug={reset.slug}
          onClose={() => resetSet({ show: false, slug: "" })}
        />
      </div>
    </Drawer>
  );
};

export default ViewSubTask;
