export const percentage_calculator = (total: number, completed: number) => {
  const average = (completed / total) * 100;
  return Math.ceil(100 - average);
};

export const completed_task = (total: number, completed: number) => {
  return Math.ceil((completed / total) * 100);
};

export const kpi_calculate = (point: number, bonus: number, total: number) => {
  const all_total = total * 2 * 100;
  const average = point + bonus;
  return Math.ceil((average / all_total) * 100);
};
