import React from "react";

// types
import { ILogin, ILoginError } from "../../../../store/types/auth";
// import { useNavigate } from "react-router-dom";

const useLogin = () => {
  // const history = useNavigate();
  // const serial = localStorage.getItem("@serial");

  const [data, dataSet] = React.useState<ILogin>({ email: "", password: "" });
  const [error, errorSet] = React.useState<ILoginError>({
    email: "",
    password: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onValidate: () => boolean = () => {
    let err = true;
    if (!data.email || data.email.length <= 5) {
      err = false;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.password) {
      err = false;
      errorSet((prev) => ({ ...prev, password: "warning" }));
    }
    return err;
  };

  // React.useEffect(() => {
  //   if (serial) {
  //     history("/");
  //   }
  // }, [history, serial]);

  return { data, error, onChangeHandler, onValidate };
};

export default useLogin;
