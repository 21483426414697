import React from "react";

// types and slecetor
import {
  IUpdateProject,
  IUpdateProjectError,
} from "../../../store/types/project";
import { useAppSelector } from "../../../store/hooks";

const useUpdateTask = () => {
  const project = useAppSelector(({ project }) => project.project);
  const [data, dataSet] = React.useState<IUpdateProject>({
    name: "",
    slug: "",
    description: "",
  });

  const [error, errorSet] = React.useState<IUpdateProjectError>({
    name: "",
    slug: "",
    description: "",
  });

  const on_change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    errorSet((prev) => ({ ...prev, [name]: "" }));
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  const on_change_text = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    errorSet((prev) => ({ ...prev, description: "" }));
    dataSet((prev) => ({ ...prev, description: e.target.value }));
  };

  const on_validate = () => {
    let validate: boolean = false;
    if (!data.slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, slug: "warning" }));
    }
    if (!data.name) {
      validate = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (validate) return false;
    return true;
  };

  const onSetProject = React.useCallback(() => {
    if (project)
      dataSet((prev) => ({
        ...prev,
        description: project.description,
        name: project.name,
        slug: project.slug,
      }));
  }, [project]);

  React.useEffect(() => {
    onSetProject();
  }, [onSetProject]);

  return { data, error, on_change, on_change_text, on_validate };
};

export default useUpdateTask;
