import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { IMiscStatus, ITaskStatus } from "../types/misc";

const initialState: IMiscStatus = {
  statuses: [],
  notification_statuses: [],
};

export const misc = createSlice({
  name: "misc",
  initialState,
  reducers: {
    setStatuses: (state, action: PayloadAction<ITaskStatus[]>) => {
      state.statuses = action.payload;
    },
    setNotificationStatuses: (state, action: PayloadAction<ITaskStatus[]>) => {
      state.notification_statuses = action.payload;
    },
  },
});

export const { setStatuses, setNotificationStatuses } = misc.actions;

export default misc.reducer;
