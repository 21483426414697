import React from 'react'

// components
import { Input } from 'antd';

// types
import { ErrorWarning } from '../../../store/types/misc';

const { TextArea } = Input

type TextInputProps = {
    placeholder: string
    prefix?: React.ReactNode
    className?: string
    value?: string | number | []
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void | undefined
    name: string
    type?: 'text' | 'password'
    status?: ErrorWarning
    label?: string
    container?: string
    required?: boolean
};

const TextInputBox: React.FC<TextInputProps> = (props) => {
    const borderColor = props.status ? 'border-[red]' : ''
    return (
        <div className={`${props.container} my-5 font-abel`}>
            {props.label && <label className='font-[600] text-[16px] py-1'>{props.label} {props.required && <span className='text-[red]'>*</span>}</label>}
            <TextArea
                placeholder={props.placeholder}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                rows={5}
                // status={props.status}
                className={`text-[16px] py-2 px-4 placeholder:font-abel placeholder:text-[16px] font-abel hover:border-[#243677] focus:border-[#243677] ${borderColor} ${props.className}`}
            />
        </div>
    )
}

export default TextInputBox