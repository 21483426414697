import React from "react";
// import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
// import { Select } from "antd";
import { useAppSelector } from "../../../store/hooks";

// const handleChange = (value: { value: string; label: React.ReactNode }) => {
//   console.log(value); // { value: "30", key: "30", label: "30 days" }
// };

const StatsDashboard: React.FC = () => {
  const total = useAppSelector(({ project }) => project.total);
  const task_total = useAppSelector(({ task }) => task.total);
  const completed = useAppSelector(({ task }) => task.completed_task);
  const failed = useAppSelector(({ task }) => task.failed_task);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 w-[100%]">
      <div className="h-[125px] bg-white rounded-10 border border-[#E4E7EC] flex flex-col justify-between col-span-1 pl-3 py-3 mx-2 my-2">
        <div className="flex justify-between items-center">
          <p className="text-primary font-medium text-16">All Projects</p>
          {/* <div className="text-[#98A2B3] text-12 flex items-center">
            <span>Last </span>
            <Select
              labelInValue
              defaultValue={{ value: "30", label: "30 days" }}
              style={{ color: "#98A2B3", fontSize: 12 }}
              className="text-[#98A2B3] text-12"
              variant="borderless"
              onChange={handleChange}
              options={[
                {
                  value: "1",
                  label: "1 day",
                },
                {
                  value: "30",
                  label: "30 days",
                },
              ]}
            />
          </div> */}
        </div>
        <div className="flex justify-between items-end mr-3">
          <p className="text-primary font-bold text-24">{total}</p>
          {/* <div className="bg-[#036B26]/10 text-[#036B26] text-12 h-[17px] w-[55px] rounded-10 flex justify-center items-center">
            <FaArrowUp /> <span className="ml-1">2.71%</span>
          </div> */}
        </div>
      </div>

      <div className="h-[125px] bg-white rounded-10 border border-[#E4E7EC] flex flex-col justify-between col-span-1 pl-3 py-3 mx-2 my-2">
        <div className="flex justify-between items-center">
          <p className="text-primary font-medium text-16">All Tasks</p>
          {/* <div className="text-[#98A2B3] text-12 flex items-center">
            <span>Last </span>
            <Select
              labelInValue
              defaultValue={{ value: "30", label: "30 days" }}
              style={{ color: "#98A2B3", fontSize: 12 }}
              className="text-[#98A2B3] text-12"
              variant="borderless"
              onChange={handleChange}
              options={[]}
            />
          </div> */}
        </div>
        <div className="flex justify-between items-end mr-3">
          <p className="text-primary font-bold text-24">{task_total}</p>
          {/* <div className="bg-[#9E0A05]/10 text-[#9E0A05] text-12 h-[17px] w-[55px] rounded-10 flex justify-center items-center">
            <FaArrowDown /> <span className="ml-1">8%</span>
          </div> */}
        </div>
      </div>

      <div className="h-[125px] bg-white rounded-10 border border-[#E4E7EC] flex flex-col justify-between col-span-1 pl-3 py-3 mx-2 my-2">
        <div className="flex justify-between items-center">
          <p className="text-primary font-medium text-16">Completed Tasks</p>
          {/* <div className="text-[#98A2B3] text-12 flex items-center">
            <span>Last </span>
            <Select
              labelInValue
              defaultValue={{ value: "30", label: "30 days" }}
              style={{ color: "#98A2B3", fontSize: 12 }}
              className="text-[#98A2B3] text-12"
              variant="borderless"
              onChange={handleChange}
              options={[]}
            />
          </div> */}
        </div>
        <div className="flex justify-between items-end mr-3">
          <p className="text-primary font-bold text-24">{completed}</p>
          {/* <div className="bg-[#036B26]/10 text-[#036B26] text-12 h-[17px] w-[55px] rounded-10 flex justify-center items-center">
            <FaArrowUp /> <span className="ml-1">5.26%</span>
          </div> */}
        </div>
      </div>
      <div className="h-[125px] bg-white rounded-10 border border-[#E4E7EC] flex flex-col justify-between col-span-1 pl-3 py-3 mx-2 my-2">
        <div className="flex justify-between items-center">
          <p className="text-primary font-medium text-16">Failed Tasks</p>
          {/* <div className="text-[#98A2B3] text-12 flex items-center">
            <span>Last </span>
            <Select
              labelInValue
              defaultValue={{ value: "30", label: "30 days" }}
              style={{ color: "#98A2B3", fontSize: 12 }}
              className="text-[#98A2B3] text-12"
              variant="borderless"
              onChange={handleChange}
              options={[]}
            />
          </div> */}
        </div>
        <div className="flex justify-between items-end mr-3">
          <p className="text-primary font-bold text-24">{failed}</p>
          {/* <div className="bg-[#9E0A05]/10 text-[#9E0A05] text-12 h-[17px] w-[55px] rounded-10 flex justify-center items-center">
            <FaArrowDown /> <span className="ml-1">1.43%</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StatsDashboard;
