import React from "react";

// components
import { Progress } from "antd";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import db from "../../../constants/database";
// import { useAppSelector } from "../../../store/hooks";

// state and service
import { get_kpis } from "../../../services/api/kpi";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// constants and utils
import { kpi_calculate } from "../../../utility/calculator";

const ReportKpi: React.FC = () => {
  const dispatch = useAppDispatch();
  const kpis = useAppSelector(({ kpi }) => kpi.kpis);

  const onLoad = React.useCallback(() => {
    get_kpis(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[18px] font-semibold px-6 mb-8">
        <p className="font-bold">Reports KPI</p>
        <Link to="/" className="flex items-center">
          <FiArrowLeft size={25} />{" "}
          <span className="ml-2 underline">Go back</span>
        </Link>
      </div>

      <div className="min-h-[310px] overflow-scroll bg-white p-4 md:p-8 md:mx-8 mx-4 mb-4">
        <p className="text-[16px] font-[900] mb-4">KPI Report Summary</p>
        <div className="relative">
          <div className="absolute flex w-full h-full ml-28 mt-6">
            {db.percent?.map((item) => (
              <div
                key={item.id}
                className="flex flex-col item-center justify-end mr-7"
              >
                <div className="w-[1px] h-[100%] bg-[#F0F2F5] ml-2"></div>
                <p className="text-[12px]">{item.percentage}%</p>
              </div>
            ))}
          </div>

          {kpis?.map((item, index) => (
            <div key={item.id} className="flex items-center w-full mb-2 pb-2">
              <p className="text-[13px] min-w-5 mr-2">{index + 1}</p>
              <p className="text-[13px] min-w-16 mr-3">
                {`${item.user?.profile?.first_name} ${item.user?.profile?.last_name}`}
              </p>
              {item?.total && (
                <Progress
                  percent={kpi_calculate(item.point, item.bonus, item.total)}
                  strokeColor={"#01008E"}
                  trailColor="#F4F4FF"
                  showInfo={true}
                  size={[300, 20]}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportKpi;
