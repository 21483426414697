import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { ITaskState, ITaskResponse } from "../types/task";

const initialState: ITaskState = {
  total: 0,
  completed_task: 0,
  failed_task: 0,
  tasks: [],
  task: null,
  loading: false,
  show: false,
  update: false,
  current_page: 1,
  last_page: 1,
};

export const task = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<ITaskResponse>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.tasks = action.payload.data;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setTotalCompletedTask: (state, action: PayloadAction<number>) => {
      state.completed_task = action.payload;
    },
    setTotalFailedTask: (state, action: PayloadAction<number>) => {
      state.failed_task = action.payload;
    },
    setDelete: (state, action: PayloadAction<string>) => {
      state.tasks = state.tasks.filter((data) => data.slug !== action.payload);
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

export const {
  setTasks,
  setTotal,
  setTotalCompletedTask,
  setTotalFailedTask,
  setDelete,
  setLoading,
  setShow,
  setUpdate,
} = task.actions;

export default task.reducer;
