import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { IAuthResponse } from "../types/auth";
import { IOrganizationUser, IUserResponse, IUserState } from "../types/user";

const initialState: IUserState = {
  users: [],
  user: null,
  current_page: 1,
  last_page: 1,
  loading: false,
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<IUserResponse>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.users = action.payload.data;
    },
    setUser: (state, action: PayloadAction<IOrganizationUser>) => {
      state.user = action.payload;
    },
    createUsers: (state, action: PayloadAction<IAuthResponse>) => {},
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setUsers, setUser, setLoading } = user.actions;

export default user.reducer;
