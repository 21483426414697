import React, { useState } from "react";

// components
// import { Layout } from "antd";
import Sidebar from "./sidebar";
import ContentComponent from "./content";

type ThemeProps = {
    children?: React.ReactNode;
};

const Theme: React.FC<ThemeProps> = ({ children }) => {
    const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);

    return (
        <div className="relative min-h-screen md:flex bg-[#FAFBFF]">
            <Sidebar setExpand={setSideMenuIsExpand} />
            <div
                className={`flex-1 min-h-screen mx-0 bg-slate-100 transition-all duration-300 ease-in-out ${sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
                    }`}
            >
                <ContentComponent>{children}</ContentComponent>
            </div>
        </div>
    );
};

export default Theme;
