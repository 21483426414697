// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification and types
// import { openNotification } from "../../components/ui/alerts";
import {
  setLoading,
  setNotification,
  setNotifications,
} from "../../store/slices/setting";
import { setNotificationStatuses } from "../../store/slices/misc";
import { AppDispatch } from "../../store";
import { INotificationUpdate } from "../../store/types/setting";

export const get_notifications = async (
  dispatch: AppDispatch,
  slug: string
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-notifications/${slug}`);

    dispatch(setNotifications(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // openNotification({
      //   status: "success",
      //   message: error?.response?.data?.message,
      // });
    }
  }
  dispatch(setLoading());
};

export const get_notification = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-notification/${slug}`);

    dispatch(setNotification(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // openNotification({
      //   status: "success",
      //   message: error?.response?.data?.message,
      // });
    }
  }
  dispatch(setLoading());
};

export const get_status_notifications = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get(`/get-notification-status`);

    dispatch(setNotificationStatuses(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const update_notification = async (
  dispatch: AppDispatch,
  data: INotificationUpdate,
  navigate: any
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch(`/confirm-notification`, data);

    // dispatch(setNotificationStatuses(response.data));
    console.log(response.data);
    navigate(0); //reload page after submission
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response?.data);
    }
  }
  dispatch(setLoading());
};
