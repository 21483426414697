import React from "react";

// components
import OtpInput from "react18-input-otp";

type IOTPInput = {
  value: string;
  handleChange: (res: string) => void;
};

const OTPInput: React.FC<IOTPInput> = ({ value, handleChange }) => {
  return (
    <OtpInput
      value={value}
      onChange={handleChange}
      numInputs={6}
      shouldAutoFocus={true}
      isInputNum={true}
      isInputSecure={true}
      focusStyle="border-2 border-[#010925]"
      inputStyle="mx-3 my-3 outline-none border-none text-[30px] bg-[#010925] bg-opacity-50"
      containerStyle="block"
    />
  );
};

export default OTPInput;
