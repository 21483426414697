import React from "react";

// types
import { format_date_time } from "../../../services/date/format";

type IEndDate = {
  end: Date;
};

const EndDate: React.FC<IEndDate> = ({ end }) => {
  return <div>{end ? format_date_time(end) : "Not a proper date"}</div>;
};

export default EndDate;
