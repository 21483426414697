// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and dispatcher and router
import { AppDispatch } from "../../store";
import { setStatuses } from "../../store/slices/misc";

export const get_statuses = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/get-status");

    dispatch(setStatuses(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};
