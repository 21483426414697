import React from "react";

// components
import AvailableTasks from "../misc/AvailableTasks";

// types and service
import { useAppDispatch } from "../../../store/hooks";
import { get_projects } from "../../../services/api/project";
import { FiArrowLeft } from "react-icons/fi";

// router
import { Link } from "react-router-dom";

const TaskDashboard = () => {
  const dispatch = useAppDispatch();
  //   {
  //     title: "S/N",
  //     width: 40,
  //     align: "center",
  //     // key: "id",
  //     // dataIndex: "admin",
  //     render: (_data, _index, num) => {
  //       return <span>{num + 1}</span>;
  //     },
  //   },
  //   {
  //     title: "Name",
  //     align: "center",
  //     key: "id",
  //     dataIndex: "project",
  //     render: (data) => {
  //       console.log(data);
  //       return <span>{data?.name}</span>;
  //     },
  //   },
  //   {
  //     title: "Supervisor",
  //     align: "center",
  //     key: "user",
  //     dataIndex: "user",
  //     render: (data) => <span>{data?.profile?.last_name}</span>,
  //   },
  //   {
  //     title: "Created At",
  //     align: "center",
  //     key: "id",
  //     dataIndex: "project",
  //     render: (data) => (
  //       <span>{data.created_at ? format_date_time(data.created_at) : ""}</span>
  //     ),
  //   },
  //   {
  //     title: "ACTION",
  //     key: "id",
  //     align: "center",
  //     render: (data) => (
  //       <div className="flex">
  //         <FaEye className="cursor-pointer mr-3" onClick={() => null} />

  //         <Popconfirm
  //           title="Delete User"
  //           description="Do you want to continue by deleting this user"
  //           okText="Confirm"
  //           cancelText="Cancel"
  //           onConfirm={() => {
  //             if (data?.id) destroy(dispatch, data.id);
  //           }}
  //         >
  //           <FaTrashCan className="cursor-pointer mr-3" />
  //         </Popconfirm>
  //       </div>
  //     ),
  //   },
  // ];

  const onLoad = React.useCallback(() => {
    get_projects(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel w-full h-[90%]">
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[20px] font-semibold px-6">
        <p className="pl-12 md:pl-0">View task</p>
        <Link to="/" className="flex items-center">
          <FiArrowLeft size={25} />{" "}
          <span className="ml-2 underline">Go back</span>
        </Link>
      </div>

      <AvailableTasks />
    </div>
  );
};

export default TaskDashboard;
