import React from "react";

import { ITaskStatus, ITaskStatuses } from "../../../store/types/task";

type IRCTaskStatus = { status: ITaskStatus };

const TaskStatus: React.FC<IRCTaskStatus> = ({ status }) => {
  return (
    <div>
      {status?.id === ITaskStatuses.COMPLETED ? (
        <div className="bg-[#E7F6EC] text-[#099137] w-min py-1 px-2 rounded-10">
          <span>{status.name}</span>
        </div>
      ) : status?.id === ITaskStatuses.STARTED ? (
        <div className="bg-[#FFF8DE] text-[#272727] w-min py-1 px-2 rounded-10">
          <span>{status.name}</span>
        </div>
      ) : status?.id === ITaskStatuses.CANCELLED ? (
        <div className="bg-[#FFDEDE] text-[#272727] w-min py-1 px-2 rounded-10">
          <span>{status.name}</span>
        </div>
      ) : (
        <div className="bg-[grey]/10 text-[#272727] w-min py-1 px-2 rounded-10">
          <span>{status.name}</span>
        </div>
      )}
    </div>
  );
};

export default TaskStatus;
