import React from 'react'

// constants
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";

export type ICheckBox = {
    name: string;
    title: string | number;
    key?: string | number
    id: string | number;
    onChange: (value: string | number) => void
    checked?: boolean;
}

const CheckBox: React.FC<ICheckBox> = ({ id, name, title, checked, onChange }) => {
    const onClick = () => {
        onChange?.(id)
    }

    return (
        <div className='cursor-pointer bg-job-bg my-2 w-full flex items-center hover:bg-[#CDD8FE] rounded-md px-4 py-3 capitalize font-poppins text-[#4E4E4E]' onClick={onClick}>
            {checked ? <BiCheckboxChecked color="#243677" size={20} /> : <BiCheckbox color="#D9D9D9" size={20} />} {title}
        </div>
    )
}

export default CheckBox
