import React from "react";

// components
import ListSubTask from "../misc/ListSubTask";

// types and service
import { get_all_tasks } from "../../../services/api/task";
import { useAppDispatch } from "../../../store/hooks";
import { FiArrowLeft } from "react-icons/fi";

// router
import { Link } from "react-router-dom";

const SubTaskDashboard = () => {
  const dispatch = useAppDispatch();

  const onLoad = React.useCallback(() => {
    get_all_tasks(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel w-full h-[90%]">
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[20px] font-semibold px-6">
        <p className="pl-12 md:pl-0">View task</p>
        <Link to="/" className="flex items-center">
          <FiArrowLeft size={25} />{" "}
          <span className="ml-2 underline">Go back</span>
        </Link>
      </div>

      <ListSubTask />
    </div>
  );
};

export default SubTaskDashboard;
