// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import {
  errorNotification,
  openNotification,
} from "../../components/ui/alerts";

// types and dispatcher
import { IChangePasswordData, ILogin } from "../../store/types/auth";
import {
  setLoading,
  setAuthResponse,
  setOrganization,
  setUser,
} from "../../store/slices/auth";
import { AppDispatch } from "../../store";
import { delete_cookie, set_cookie } from "../../utility/cookies";
import handler from "../../constants/responseErrorHandler";

export const auth = async (data: ILogin, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/login", data);

    set_cookie("@serial", response.data?.data?.token);
    dispatch(setAuthResponse(response.data?.data));
    openNotification({
      status: "success",
      message: response?.data?.message,
    });
    window.location.href = "/dashboard";
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const get_organization = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/user-organization");

    dispatch(setOrganization(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error);
    }
  }
  dispatch(setLoading());
};

export const get_user = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-profile");

    dispatch(setUser(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

type IForgotPassword = (
  dispatch: any,
  formData: { email: string }
) => Promise<boolean>;
export const forgot_password: IForgotPassword = async (dispatch, formData) => {
  dispatch(setLoading());
  let result = false;
  try {
    await axiosInstance.post("/auth/forgot-password", formData);

    localStorage.setItem("@email", formData?.email);
    result = true;
  } catch (error) {
    result = false;
    handler(error);
  }
  dispatch(setLoading());
  return result;
};

type IChangePassword = (
  dispatch: Function,
  formData: IChangePasswordData,
  navigate: Function
) => Promise<void>;
export const change_password: IChangePassword = async (
  dispatch,
  formData,
  navigate
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/auth/change-password",
      formData
    );

    if (response?.data?.message === "Please input the correct details.") {
      throw new Error("Please input the correct details.");
    } else {
      localStorage.removeItem("@email");
      openNotification({ status: "success", message: response?.data?.message });
      navigate("/login");
    }
  } catch (error) {
    handler(error);
    errorNotification({
      status: "error",
      message: "Incorrect Token provided.",
    });
  }
  dispatch(setLoading());
};

export const logout = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    delete_cookie("@serial");
    get_organization(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};
