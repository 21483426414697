import React from "react";

// components
import { Layout } from "antd";
import Navbar from "../header/Navbar";
// import BreadCrumb from '../breadcrumb';

const { Content } = Layout;

type ThemeProps = {
  children: React.ReactNode;
};

const ContentComponent: React.FC<ThemeProps> = ({ children }) => {
  return (
    <Content className="h-[100%] bg-[#FAFBFF] pb-20 shadow-sm font-abel">
      <Navbar />
      <div className="h-full overflow-y-scroll font-abel">{children}</div>
    </Content>
  );
};

export default ContentComponent;
