import React from "react";
import TextInput from "../../../components/elements/forms/TextInput";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import TextInputBox from "../../../components/elements/forms/TextInputBox";

// types, hooks and service
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_organization } from "../../../services/api/auth";
import {
  ICreateProject,
  ICreateProjectStatus,
} from "../../../store/types/project";
import { create } from "../../../services/api/project";
import { useNavigate } from "react-router-dom";

const CreateTask: React.FC = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(({ auth }) => auth.organization);
  const loading = useAppSelector(({ project }) => project.loading);
  const navigate = useNavigate();
  const [data, dataSet] = React.useState<ICreateProject>({
    description: "",
    name: "",
    organization_slug: "",
    supervisor: "",
  });
  const [error, errorSet] = React.useState<ICreateProjectStatus>({
    description: "",
    name: "",
    organization_slug: "",
    supervisor: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
    if (!data.organization_slug && organization)
      dataSet((prev) => ({ ...prev, organization_slug: organization?.slug }));
  };

  const onSubmitHandler = () => {
    let validate: boolean = false;
    if (!data.organization_slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, organization_slug: "warning" }));
    }
    if (!data.name) {
      validate = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.supervisor) {
      validate = true;
      errorSet((prev) => ({ ...prev, supervisor: "warning" }));
    }
    if (validate) return;
    create(dispatch, data, navigate);
    // console.log(data);
  };

  const onLoad = React.useCallback(() => {
    get_organization(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  return (
    <div className="font-abel flex justify-center items-center h-[90%] pr-4 pl-14 md:pr-4 md:pl-4 pb-5 w-full py-4">
      <div className="max-w-456 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-7 px-7 w-full">
          <h1 className="text-24 font-bold text-black text-center">
            Create Project
          </h1>
          <TextInput
            label="Project Name"
            placeholder="Enter Subject"
            status={error.name}
            name="name"
            value={data.name}
            onChange={onChangeHandler}
            container="mb-4"
          />
          <TextInput
            label="Task Owner | Supervisor"
            placeholder="Enter email"
            status={error.supervisor}
            name="supervisor"
            value={data.supervisor}
            onChange={onChangeHandler}
          />
          <TextInputBox
            label="Task Details"
            placeholder="Enter text here..."
            status={error.description}
            name="description"
            value={data.description}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              if (!data.organization_slug && organization)
                dataSet((prev) => ({
                  ...prev,
                  organization_slug: organization?.slug,
                }));
            }}
          />
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Create Project"
                onSubmitHandler={onSubmitHandler}
                loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTask;
