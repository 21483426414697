import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  INotification,
  INotificationResponse,
  ISettingStatus,
} from "../types/setting";

const initialState: ISettingStatus = {
  notifications: [],
  notification: null,
  notification_current: 1,
  notification_last: 1,
  loading: false,
};

export const setting = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<INotificationResponse>) => {
      state.notification_current = action.payload.current_page;
      state.notification_last = action.payload.last_page;
      state.notifications = action.payload.data;
    },
    setNotification: (state, action: PayloadAction<INotification>) => {
      state.notification = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
  },
});

export const { setNotifications, setNotification, setLoading, setShow } =
  setting.actions;

export default setting.reducer;
