// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification and types
import { setKpis, setLoading } from "../../store/slices/kpi";
import { AppDispatch } from "../../store";
import {} from "../../store/types/kpi";

export const get_kpis = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-overall-kpi");

    dispatch(setKpis(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // openNotification({
      //   status: "success",
      //   message: error?.response?.data?.message,
      // });
    }
  }
  dispatch(setLoading());
};
