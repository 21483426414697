import React from "react";
import TextInput from "../../../components/elements/forms/TextInput";
// import useTask from '../../hooks';
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import TextInputBox from "../../../components/elements/forms/TextInputBox";

const UpdateSubTask: React.FC = () => {
  // const { onChangeHandler } = useTask();

  return (
    <div className="font-abel flex justify-center items-center h-[90%] px-4 py-4">
      <div className="max-w-950 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-7 px-7 w-full">
          <h1 className="text-24 font-bold text-black text-center">
            Update Subtask
          </h1>
          <TextInput
            label="Subtask Name"
            placeholder="Enter Subject"
            status={""}
            name="text"
            value={""}
            // onChange={onChangeHandler}
            container="mb-4"
          />
          <TextInput
            label="Task Owner(s)"
            placeholder="Enter Owner"
            status={""}
            name="text"
            value={""}
            // onChange={onChangeHandler}
            container="mb-4"
          />
          <TextInput
            label="Task Supervisor"
            placeholder="Enter Subject"
            status={""}
            name="text"
            value={""}
            // onChange={onChangeHandler}
            container="mb-4"
          />
          <div className="flex md:justify-between flex-wrap">
            <TextInput
              label="Start Date"
              placeholder="Enter Subject"
              status={""}
              name="text"
              value={""}
              type="datetime-local"
              // onChange={onChangeHandler}
              container="mb-4 md:w-[49%] w-full"
            />
            <TextInput
              label="End Date"
              placeholder="Enter Subject"
              status={""}
              name="text"
              value={""}
              type="datetime-local"
              // onChange={onChangeHandler}
              container="mb-4 md:w-[49%] w-full"
            />
          </div>
          {/* <div className="flex md:justify-between flex-wrap">
            <TextInput
              label="Start Time"
              placeholder="Enter Subject"
              status={""}
              name="text"
              value={""}
              type="time"
              // onChange={onChangeHandler}
              container="mb-4 md:w-[49%] w-full"
            />
            <TextInput
              label="End Time"
              placeholder="Enter Subject"
              status={""}
              name="text"
              value={""}
              type="time"
              // onChange={onChangeHandler}
              container="mb-4 md:w-[49%] w-full"
            />
          </div> */}
          <TextInputBox
            label="Task Details"
            placeholder="Enter text here..."
            status={""}
            name="text"
            value={""}
            // onChange={onChangeHandler}
          />
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Update Subtask"
                onSubmitHandler={() => {
                  // const validate = onValidate();
                  // if (validate) {
                  //     auth(data, dispatch);
                  // }
                }}
                // loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSubTask;
