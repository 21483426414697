import React from "react";

// types
import { format_date_time } from "../../../services/date/format";

type IStartDate = {
  start_time: Date;
};

const StartDate: React.FC<IStartDate> = ({ start_time }) => {
  return (
    <div>{start_time ? format_date_time(start_time) : "Not a proper date"}</div>
  );
};

export default StartDate;
