import React from "react";

// components
import { Tabs } from "antd";
import type { TabsProps } from "antd";

// router and icons
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { get_notifications } from "../../../services/api/setting";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_organization } from "../../../services/api/auth";
import ListNotification from "../misc/ListNotification";

const Settings: React.FC = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(({ auth }) => auth.organization);
  const onChange = (key: string) => console.log("first", key);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Notification",
      children: <ListNotification />,
    },
  ];

  const onLoadProfile = React.useCallback(() => {
    if (!organization?.slug) get_organization(dispatch);
  }, [dispatch, organization?.slug]);

  const onLoad = React.useCallback(() => {
    if (organization?.slug) get_notifications(dispatch, organization.slug);
  }, [dispatch, organization?.slug]);

  React.useEffect(() => {
    onLoad();
    onLoadProfile();
  }, [onLoad, onLoadProfile]);

  return (
    <div>
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[18px] font-semibold px-6 mb-8">
        <p className="font-bold pl-12 md:pl-0">Settings</p>
        <Link to="/" className="flex items-center">
          <FiArrowLeft size={25} />{" "}
          <span className="ml-2 underline">Go back</span>
        </Link>
      </div>

      <div className="pl-12 md:pl-6 md:pr-4 overflow-x-scroll">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
};

export default Settings;
