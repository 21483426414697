import React from "react";

// components
import AvailableTasks from "../misc/AvailableTasks";
import StatsDashboard from "../misc/StatsDashboard";
import ProgressBar from "../misc/ProgressBar";

// types and service
import { get_total_project } from "../../../services/api/project";
import {
  get_all_tasks,
  get_number_of_completed_task,
  get_number_of_failed_task,
  get_number_of_tasks,
} from "../../../services/api/task";
import { useAppDispatch } from "../../../store/hooks";

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const onLoad = React.useCallback(() => {
    get_all_tasks(dispatch);
    get_number_of_completed_task(dispatch);
    get_number_of_failed_task(dispatch);
    get_number_of_tasks(dispatch);
    get_total_project(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="pr-4 pl-14 md:pr-4 md:pl-4 pb-5 w-full">
      <ProgressBar />
      <StatsDashboard />
      <p className="text-16 font-bold text-[#100F0F] pt-5 mx-2">
        Newly added tasks
      </p>
      <AvailableTasks />
    </div>
  );
};

export default Dashboard;
