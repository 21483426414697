import React from "react";

// state and types
import { useAppSelector } from "../../../store/hooks";

// components
import { Table, TableProps } from "antd";
import {
  INotification,
  NotificationStatus,
} from "../../../store/types/setting";
import { FaEye } from "react-icons/fa";
import ShowNotification from "./ShowNotification";
import { format_date_time } from "../../../services/date/format";

const ListNotification = () => {
  const notifications = useAppSelector(({ setting }) => setting.notifications);
  const [show, showSet] = React.useState<boolean>(false);
  const [slug, slugSet] = React.useState<string>();

  const columns: TableProps<INotification>["columns"] = [
    {
      title: "Task Name",
      dataIndex: "task",
      align: "center",
      render: (data) => (
        <span
          onClick={() => {
            slugSet(data);
            showSet(true);
          }}
          className="capitalize cursor-pointer"
        >
          {data?.name}
        </span>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "task",
      align: "center",
      render: (data) => (
        <span className="capitalize">{data?.project?.name}</span>
      ),
    },
    {
      title: "TASK OWNER",
      dataIndex: "task",
      align: "center",
      render: (data) => {
        if (data?.assigned) {
          const { profile } = data.assigned;
          return (
            <span className="capitalize">{`${profile?.first_name} ${profile?.last_name}`}</span>
          );
        }
        return <span>Not Assigned</span>;
      },
    },
    {
      title: "END DATE",
      dataIndex: "task",
      align: "center",
      render: (data) => (
        <span>{data?.end_time ? format_date_time(data.end_time) : ""}</span>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "slug",
      align: "center",
      render: (data) => (
        <div className="w-full flex justify-center">
          <FaEye
            className="cursor-pointer"
            onClick={() => {
              slugSet(data);
              showSet(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="font-abel">
      <Table
        dataSource={notifications}
        columns={columns}
        rowClassName={(row) =>
          Number(row?.seen) === NotificationStatus.UNSORTED
            ? "bg-[#CDD8FE]"
            : "bg-[white]"
        }
        key="slug"
        rowKey="slug"
        className="font-abel"
      />
      {slug?.length && show && (
        <ShowNotification
          open={show}
          slug={slug}
          onClose={() => {
            slugSet("");
            showSet(false);
          }}
        />
      )}
    </div>
  );
};

export default ListNotification;
