import React from "react";

// types
import { ICreateUser, ICreateUserError } from "../../../store/types/user";
import { useAppSelector } from "../../../store/hooks";

const useCreateUser = () => {
  const organization = useAppSelector(({ auth }) => auth.organization);
  const [data, dataSet] = React.useState<ICreateUser>({
    email: "",
    first_name: "",
    last_name: "",
    admin: false,
    organization_slug: "",
  });
  const [error, errorSet] = React.useState<ICreateUserError>({
    email: "",
    first_name: "",
    last_name: "",
    organization_slug: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onValidate = () => {
    let validate: boolean = false;
    if (!data.organization_slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, organization_slug: "warning" }));
    }
    if (!data.email) {
      validate = true;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.first_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, first_name: "warning" }));
    }
    if (!data.last_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, last_name: "warning" }));
    }
    return validate ? false : true;
  };

  const onLoad = React.useCallback(() => {
    if (organization)
      dataSet((prev) => ({ ...prev, organization_slug: organization.slug }));
  }, [organization]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return { error, data, dataSet, onChangeHandler, onValidate };
};

export default useCreateUser;
