import React from "react";

// <FiPlusCircle size={20} />

type IButton = {
  title: string;
  icon?: React.ReactNode;
  onClick: () => void;
  className?: string;
};

const Button: React.FC<IButton> = ({ title, icon, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-between bg-primary text-white hover:bg-black hover:text-white decoration-white rounded-8 text-14 font-bold h-[36px] w-[115px] px-3 ml-4 mr-3 ${className}`}
    >
      {icon} <span>{title}</span>
    </button>
  );
};

export default Button;
