import React from "react";

// components
import EndDate from "./EndDate";
import StartDate from "./StartDate";
import type { TableProps } from "antd";
import ViewSubTask from "./ViewSubTask";
import { Popconfirm, Table } from "antd";
import TaskStatus from "../../../components/ui/status/TaskStatus";
import UpdateTaskStatus from "../../subtask/misc/UpdateTaskStatus";
import AssingUser from "../../subtask/misc/AssingUser";

// react router
// import { useNavigate } from "react-router-dom";

// cointants and icons
import { FaEye, FaTrashCan } from "react-icons/fa6";
// import { format_date_time } from "../../../services/date/format";

// types and service
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { destroy } from "../../../services/api/task";
import { ITask } from "../../../store/types/task";
import TimeLeft from "../../../components/ui/status/TimeLeft";

type IShowTask = {
  show: boolean;
  task: ITask | null;
};

type ITaskUpdate = {
  slug: string;
  open_assign: boolean;
  open_status: boolean;
};

const ListSubTasks: React.FC = () => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(({ task }) => task.tasks);
  const [show, showSet] = React.useState<IShowTask>({
    show: false,
    task: null,
  });
  console.log(show);

  const [update, updateSet] = React.useState<ITaskUpdate>({
    slug: "",
    open_assign: false,
    open_status: false,
  });

  const columns: TableProps<ITask>["columns"] = [
    {
      title: "TASKS",
      render: (data) => (
        <span
          onClick={() => showSet({ show: true, task: data })}
          className="capitalize cursor-pointer"
        >
          {data?.name}
        </span>
      ),
    },
    {
      title: "TASK OWNER",
      dataIndex: "assigned",
      render: (data) => {
        if (data?.profile)
          return (
            <span>{`${data?.profile?.first_name} ${data?.profile?.last_name}`}</span>
          );
        return "Not Assigned";
      },
    },
    {
      title: "TIME LEFT",
      align: "center",
      render: (data) => (
        <TimeLeft start_time={data.start_time} end_time={data.end_time} />
      ),
    },
    // {
    //   title: "DATE CREATED",
    //   align: "center",
    //   render: (data) => (
    //     <span>{data?.created_at ? format_date_time(data.created_at) : ""}</span>
    //   ),
    // },
    {
      title: "START DATE",
      dataIndex: "start_time",
      render: (data) => <StartDate start_time={data} />,
    },
    {
      title: "END DATE",
      dataIndex: "end_time",
      render: (data) => <EndDate end_time={data} />,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (data) => <TaskStatus status={data} />,
    },
    {
      title: "ACTION",
      align: "center",
      render: (data) => (
        <div className="flex">
          <FaEye
            className="cursor-pointer mr-3"
            onClick={() => showSet({ show: true, task: data })}
          />
          {Number(data.status.id) === 1 && (
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              placement="leftBottom"
              onConfirm={() => {
                if (data?.slug) destroy(dispatch, data?.slug);
              }}
              onCancel={() => null}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FaTrashCan className="cursor-pointer" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-abel mx-2 overflow-x-scroll">
      <Table dataSource={tasks} columns={columns} key="slug" rowKey="slug" />

      {show.show && show.task ? (
        <ViewSubTask
          task={show.task}
          show={show.show}
          onClose={() => showSet({ show: false, task: null })}
          onAssignUser={(res) => {
            updateSet((prev) => ({ ...prev, open_assign: true, slug: res }));
            showSet({ show: false, task: null });
          }}
          onUpdateStatus={(res) => {
            updateSet((prev) => ({ ...prev, open_status: true, slug: res }));
            showSet({ show: false, task: null });
          }}
        />
      ) : null}

      {update.slug && update.open_status && (
        <UpdateTaskStatus
          open={update.open_status}
          slug={update.slug}
          onClose={() =>
            updateSet({ open_assign: false, open_status: false, slug: "" })
          }
        />
      )}

      {update.slug && update.open_assign && (
        <AssingUser
          open={update.open_assign}
          slug={update.slug}
          onClose={() =>
            updateSet({ open_assign: false, open_status: false, slug: "" })
          }
        />
      )}
    </div>
  );
};

export default ListSubTasks;
