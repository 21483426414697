import React from "react";

// components
import { Popconfirm, Table } from "antd";
import type { TableProps } from "antd";

// types
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IOrganizationUser } from "../../../store/types/user";

//contants
import { FaEye, FaTrashCan } from "react-icons/fa6";
import { format_date_time } from "../../../services/date/format";
import { destroy } from "../../../services/api/project_user";

const OrganizationUser: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector(({ user }) => user.users);

  const columns: TableProps<IOrganizationUser>["columns"] = [
    {
      title: "S/N",
      width: 40,
      align: "center",
      key: "id",
      dataIndex: "admin",
      render: (_data, _index, num) => {
        return <span>{num + 1}</span>;
      },
    },
    {
      title: "Firstname",
      align: "center",
      key: "id",
      dataIndex: "user",
      render: (data) => <span>{data?.profile?.first_name}</span>,
    },
    {
      title: "Lastname",
      align: "center",
      key: "user",
      dataIndex: "user",
      render: (data) => <span>{data?.profile?.last_name}</span>,
    },
    // {
    //   title: "Username",
    //   align: "center",
    //   key: "id",
    //   dataIndex: "user",
    //   render: (data) => <span>{data?.username}</span>,
    // },
    {
      title: "Email",
      align: "center",
      key: "id",
      dataIndex: "user",
      render: (data) => <span>{data?.email}</span>,
    },
    {
      title: "Joined At",
      align: "center",
      key: "id",
      render: (data) =>
        data?.created_at ? format_date_time(data?.created_at) : "Not Available",
    },
    {
      title: "ACTION",
      key: "id",
      align: "center",
      render: (data) => (
        <div className="flex">
          <FaEye
            className="cursor-pointer mr-3"
            onClick={() => navigate(`/user/${data?.id}`)}
          />

          <Popconfirm
            title="Delete User"
            description="Are you sure you want to delete this user?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => {
              if (data?.id) destroy(dispatch, data.id);
            }}
          >
            <FaTrashCan className="cursor-pointer mr-3" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-abel mx-2 pl-12 md:pl-0 overflow-x-scroll">
      <Table dataSource={users} columns={columns} key="id" rowKey="id" />
    </div>
  );
};

export default OrganizationUser;
