import React from "react";

// icons and constants
import { CiEdit } from "react-icons/ci";

type ISetOwner = {
  on_edit: () => void;
};

const SetOwner: React.FC<ISetOwner> = ({ on_edit }) => {
  return (
    <div className="flex items-center">
      Not Set <CiEdit className="ml-2 cursor-pointer" onClick={on_edit} />
    </div>
  );
};

export default SetOwner;
