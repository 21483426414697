import React from "react";

// components

type TextInputProps = {
  placeholder: string;
  prefix?: React.ReactNode;
  className?: string;
  value?: string | number | [];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  type?: "text" | "password" | "date" | "time" | "datetime-local";
  status?: "warning" | "";
  label?: string;
  container?: string;
  required?: boolean;
  input_style?: string;
  onBlur?: () => void;
};

const TextInput: React.FC<TextInputProps> = (props) => {
  const borderColor = props.status ? "border-[red]" : "border-[#e8e8e8]";
  return (
    <div className={`${props.container} font-abel`}>
      {props.label && (
        <label className="font-[600] block text-[#303030]`">
          {props.label}{" "}
          {props.required && <span className="text-[red]">*</span>}
        </label>
      )}
      <div
        className={`flex text-[16px] py-3 px-4 rounded-6 border hover:border-[#243677] bg-[#ffffff] focus-within:border-[#243677] h-[55px] ${props.input_style} ${borderColor}`}
      >
        {props.prefix && <div>{props.prefix}</div>}
        <div className="w-full bg-[#fff]">
          <input
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            type={props.type}
            onBlur={props.onBlur}
            className="border-none placeholder:font-abel placeholder:text-[16px] outline-none w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default TextInput;
