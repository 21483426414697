import React from "react";

// types and services
import { get_statuses } from "../../../services/api/misc";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IUpdateStatus } from "../../../store/types/task";
import { update_status } from "../../../services/api/task";

// components
import { Modal } from "antd";
import SelectInput from "../../../components/elements/forms/SelectInput";
import { get_organization } from "../../../services/api/auth";
import { useNavigate } from "react-router-dom";

export type IUpdateTaskStatus = {
  open: boolean;
  slug: string;
  onClose: () => void;
};

const UpdateTaskStatus: React.FC<IUpdateTaskStatus> = ({
  open,
  slug,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(({ misc }) => misc.statuses);
  const loading = useAppSelector(({ task }) => task.loading);
  const org = useAppSelector(({ auth }) => auth.organization);

  const navigate = useNavigate();

  const [data, dataSet] = React.useState<IUpdateStatus>({
    slug: "",
    status_id: null,
    organization_slug: "",
  });

  const onLoad = React.useCallback(() => {
    if (!org?.slug) get_organization(dispatch);
    get_statuses(dispatch);
    dataSet((prev) => ({ ...prev, slug, organization_slug: org?.slug || "" }));
  }, [dispatch, org?.slug, slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      title="Update Status"
      open={open}
      onOk={() => {
        if (data.status_id && data.slug)
          update_status(dispatch, data, navigate);
      }}
      onCancel={onClose}
      confirmLoading={loading}
      centered={true}
    >
      <SelectInput
        status=""
        items={statuses}
        name="status_id"
        on_select={(e) => {
          const { name, value } = e.target;
          dataSet((prev) => ({ ...prev, [name]: parseInt(value) }));
        }}
      />
    </Modal>
  );
};

export default UpdateTaskStatus;
