import React from "react";

// routes
import { Route, Routes } from "react-router-dom";

// themes and components
import Theme from "../../themes";
import CreateTask from "../../screens/task/create";
import UpdateTask from "../../screens/task/update";
import TaskDashboard from "../../screens/task/dashboard";
import ShowDetial from "../../screens/task/show";
import {
  CreateSubTask,
  SubTaskDashboard,
  UpdateSubTask,
} from "../../screens/subtask";

// components
import { Dashboard } from "../../screens/dashboard";
import { UserDashboard, CreateUser, ShowDetail } from "../../screens/user";
import { NotFound } from "../../screens/error";
import ReportKpi from "../../screens/reports-kpi/dashboard";
import Settings from "../../screens/settings/dashboard";

const AuthRoute: React.FC = () => {
  return (
    <Theme>
      <Routes>
        {/* User */}
        <Route path="/create-user" element={<CreateUser />} />
        <Route path="/users" element={<UserDashboard />} />
        <Route path="/user/:id" element={<ShowDetail />} />

        {/* projects -->> task */}
        <Route path="/create-task" element={<CreateTask />} />
        <Route path="/tasks" element={<TaskDashboard />} />
        <Route path="/task/:slug" element={<ShowDetial />} />

        {/* tasks --->>>> subtask */}
        <Route path="/update-task/:slug" element={<UpdateTask />} />

        {/* Syb task */}
        <Route path="/subtasks" element={<SubTaskDashboard />} />
        <Route
          path="/create-subtask/:project_slug"
          element={<CreateSubTask />}
        />
        {/* <Route path="/create-subtask" element={<CreateSubTask />} /> */}

        {/* sub tasks */}
        <Route path="/update-subtask" element={<UpdateSubTask />} />

        {/* Reports & KPI  */}
        <Route path="/reports" element={<ReportKpi />} />

        {/* Settings  */}
        <Route path="/settings" element={<Settings />} />

        {/* dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Theme>
  );
};

export default AuthRoute;
