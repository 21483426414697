import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { IProject, IProjectResponse, IProjectState } from "../types/project";

const initialState: IProjectState = {
  total: 0,
  projects: [],
  project: null,
  loading: false,
  show: false,
  update: false,
  current_page: 1,
  last_page: 1,
};

export const project = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<IProjectResponse>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.projects = action.payload.data;
    },
    setProject: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload;
    },
    setDelete: (state, action: PayloadAction<{ slug: string }>) => {
      const projects = state.projects.filter(
        (item) => item.project.slug !== action.payload.slug
      );
      console.log(projects);
      state.projects = projects;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

export const {
  setProjects,
  setProject,
  setDelete,
  setTotal,
  setLoading,
  setShow,
  setUpdate,
} = project.actions;

export default project.reducer;
