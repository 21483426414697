import React from "react";

// types and services
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// components
// import OrganizationUser from "../misc/OrganizationUser";
import Button from "../../../components/elements/button/Button";

// constants and constants
import { RiAdminLine } from "react-icons/ri";
import avatar from "../../../assets/images/svg/icons/user.png";

// router
import { Link, useParams } from "react-router-dom";
import { get_user, update } from "../../../services/api/project_user";
import { format_date_time } from "../../../services/date/format";
import { get_organization } from "../../../services/api/auth";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import { FiArrowLeft } from "react-icons/fi";

const ShowDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ user }) => user.user);
  const organization = useAppSelector(({ auth }) => auth.organization);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_user(dispatch, id);
      get_organization(dispatch);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="pl-4 md:px-5 pb-5 w-full font-abel">
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[18px] font-semibold px-6">
        <p className="font-bold">User Details</p>
        <Link to="/users" className="flex items-center">
          <FiArrowLeft size={25} />{" "}
          <span className="ml-2 underline">Go back</span>
        </Link>
      </div>
      <div className="w-full my-2 max-w-[500px] mx-auto">
        <div className="border border-[#D0D5DD] bg-[#F1F4FF] object-cover overflow-hidden p-2 w-[75px] h-[75px] flex items-center justify-center rounded-[100%] mx-auto">
          <img
            src={
              user?.user?.profile?.avatar ? user?.user?.profile?.avatar : avatar
            }
            alt={user?.user?.profile?.first_name}
            className="w-[60px]"
          />
        </div>
        <label className="font-bold text-[16px]">First Name</label>
        <div className="border border-[#D0D5DD] bg-[#F1F4FF] mt-1 mb-3 pl-4 rounded-6">
          <p className="text-16 font-bold text-[#100F0F] py-2">
            {user?.user?.profile?.first_name}
          </p>
        </div>
        <label className="font-bold text-[16px]">Last Name</label>
        <div className="border border-[#D0D5DD] bg-[#F1F4FF] mt-1 mb-3 pl-4 rounded-6">
          <p className="text-16 font-bold text-[#100F0F] py-2">
            {user?.user?.profile?.last_name}
          </p>
        </div>
        <label className="font-bold text-[16px]">Status</label>
        <div className="border border-[#D0D5DD] bg-[#F1F4FF] mt-1 mb-3 pl-4 rounded-6 flex items-center justify-between">
          <p className="text-16 font-bold text-[#100F0F] py-2">
            {user?.admin && <span>Admin</span>}
          </p>
          <Button
            title={user?.admin ? "Remove Access" : "Make Admin"}
            onClick={() => {
              if (user && id && organization)
                update(dispatch, {
                  admin: !user?.admin,
                  id: parseInt(id),
                  organization_slug: organization?.slug,
                });
            }}
            icon={<RiAdminLine size={20} />}
            className="w-[135px]"
          />
        </div>

        <label className="font-bold text-[16px]">Email</label>
        <div className="border border-[#D0D5DD] bg-[#F1F4FF] mt-1 mb-3 pl-4 rounded-6">
          <p className="text-16 font-bold text-[#100F0F] py-2">
            {user?.user?.email}
          </p>
        </div>
        <label className="font-bold text-[16px]">Date Created</label>
        <div className="border border-[#D0D5DD] bg-[#F1F4FF] mt-1 mb-5 pl-4 rounded-6">
          <p className="text-16 font-bold text-[#100F0F] py-2">
            {user?.created_at && format_date_time(user?.created_at)}
          </p>
        </div>
        <div className="text-center">
          <SubmitButton
            title="Update User"
            onSubmitHandler={() => null}
            // loading={loading}
            className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[169px]"
          />
        </div>
      </div>

      {/* <OrganizationUser /> */}
    </div>
  );
};

export default ShowDetail;
