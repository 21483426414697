import React from "react";

// components
import { ErrorWarning } from "../../../store/types/misc";

type TextInputProps = {
  name: string;
  label?: string;
  container?: string;
  items: { id: number; name: string }[];
  on_select: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  prefix?: React.ReactNode;
  className?: string;
  status: ErrorWarning;
};

const SelectInput: React.FC<TextInputProps> = ({
  name,
  status,
  label,
  container,
  items,
  on_select,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#e8e8e8]";

  return (
    <div className={`${container} my-5 font-abel outline-none`}>
      <select
        id={name}
        name={name}
        onChange={on_select}
        className={`w-full h-[48px] font-abel bg-gray-50 py-3 px-4 ${borderColor} border outline-none text-[#303030] text-sm rounded-lg hover:border-[#243677] focus-within:border-[#243677] block p-2.5 capitalize`}
      >
        <option defaultValue="">{label || "Please Select..."}</option>
        {items?.map((item) => (
          <option value={item?.id} key={item?.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
