const sidebarStructure = [
  {
    id: "dashboard",
    title: "Home",
    name: "home",
    parent: true,
    icon: "home",
    link: "/dashboard",
  },
  {
    id: "createTask",
    title: "Create Project",
    name: "createTask",
    parent: true,
    icon: "createTask",
    link: "/create-task",
  },
  {
    id: "tasks",
    title: "Projects",
    name: "tasks",
    parent: true,
    icon: "viewTask",
    link: "/tasks",
  },
  {
    id: "subtasks",
    title: "All Tasks",
    name: "subtasks",
    parent: true,
    icon: "viewSubtask",
    link: "/subtasks",
  },
  // {
  //   id: "candidates",
  //   title: "Candidates",
  //   name: "candidates",
  //   parent: true,
  //   icon: "candidates",
  //   link: "/candidates/",
  // },
  {
    id: "userAccounts",
    title: "User Accounts",
    name: "userAccounts",
    parent: true,
    icon: "user",
    link: "/users",
  },
  {
    id: "reports",
    title: "Reports & KPI",
    name: "reports",
    parent: true,
    icon: "reports",
    link: "/reports",
  },
  {
    id: "settings",
    title: "Settings",
    name: "settings",
    parent: true,
    icon: "settings",
    link: "/settings",
  },
  // {
  //   id: "helpCenter",
  //   title: "Help Center",
  //   name: "helpCenter",
  //   parent: true,
  //   icon: "help",
  //   link: "/help/",
  // },
  // {
  //   id: "refer",
  //   title: "Refer family & friends",
  //   name: "refer",
  //   parent: true,
  //   icon: "refer",
  //   link: "/refer",
  // },
];

export { sidebarStructure };
