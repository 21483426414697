import React from "react";
import { ICreateTask, ICreateTaskError } from "../../../store/types/task";
import { useAppSelector } from "../../../store/hooks";
import moment from "moment";
import { useParams } from "react-router-dom";

const useSubTask = () => {
  const { project_slug } = useParams();
  const organization = useAppSelector(({ auth }) => auth.organization);

  const [data, dataSet] = React.useState<ICreateTask>({
    end_time: "",
    name: "",
    organization_slug: "",
    project_slug: "",
    start_time: "",
    email: "",
    description: "",
  });

  const [error, errorSet] = React.useState<ICreateTaskError>({
    description: "",
    email: "",
    end_time: "",
    name: "",
    organization_slug: "",
    project_slug: "",
    start_time: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const date = moment(value).format("YYYY-MM-DD HH:mm:ss");
    dataSet((prev) => ({ ...prev, [name]: date }));
  };

  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onValidate = () => {
    let validate: boolean = false;
    if (!data.project_slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, project_slug: "warning" }));
    }
    if (!data.organization_slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, organization_slug: "warning" }));
    }
    if (!data.end_time) {
      validate = true;
      errorSet((prev) => ({ ...prev, end_time: "warning" }));
    }
    if (!data.start_time) {
      validate = true;
      errorSet((prev) => ({ ...prev, start_time: "warning" }));
    }
    if (!data.name) {
      validate = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    return validate ? false : true;
  };

  const onLoad = React.useCallback(() => {
    if (organization && project_slug)
      dataSet((prev) => ({
        ...prev,
        organization_slug: organization.slug,
        project_slug,
      }));
  }, [project_slug, organization]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return { data, error, onChange, onChangeDate, onChangeText, onValidate };
};

export default useSubTask;
