import React from "react";
import { get_week_past } from "../../../services/date/format";
// import { IDeadline } from "../../../store/types/task";

type ITimeFrame = {
  end_time: Date;
};

const TimeFrame: React.FC<ITimeFrame> = ({ end_time }) => {
  return (
    <p className="text-[#344054]">{end_time && get_week_past(end_time)}</p>
  );
};

export default TimeFrame;
