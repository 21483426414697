import React from "react";

// components
import TaskStatus from "../../../components/ui/status/TaskStatus";
import TimeLeft from "../../../components/ui/status/TimeLeft";
import type { TableProps } from "antd";
import { Table } from "antd";

// react router
import { useNavigate } from "react-router-dom";

// cointants and icons
import { FaEye } from "react-icons/fa6";

// types and service
import { useAppSelector } from "../../../store/hooks";
import { ITask } from "../../../store/types/task";

// import TimeLeft from "../../../components/ui/status/TimeLeft";
import StartDate from "../../subtask/misc/StartDate";
import EndDate from "../../subtask/misc/EndDate";

const AvailableTasks: React.FC = () => {
  const navigate = useNavigate();
  const tasks = useAppSelector(({ task }) => task.tasks)?.slice(0, 5);

  const columns: TableProps<ITask>["columns"] = [
    {
      title: "TASKS",
      align: "center",
      render: (data) => (
        <span
          className="capitalize cursor-pointer"
          onClick={() => navigate(`/task/${data?.slug}`)}
        >
          {data?.name}
        </span>
      ),
    },
    {
      title: "PROJECTS",
      dataIndex: "project",
      render: (data) => (
        <span className="capitalize cursor-pointer">{data?.name}</span>
      ),
    },
    {
      title: "TASK OWNER",
      dataIndex: "assigned",
      align: "center",
      render: (data) => {
        if (data?.profile) {
          return (
            <span>{`${data?.profile?.first_name} ${data?.profile?.last_name}`}</span>
          );
        }
        return <span>Not Assigned</span>;
      },
    },
    {
      title: "TIME LEFT",
      align: "center",
      render: (data) => (
        <TimeLeft end_time={data?.end_time} start_time={data?.start_time} />
      ),
    },
    {
      title: "START DATE",
      dataIndex: "start_time",
      render: (data) => {
        if (data) return <StartDate start={data} />;
        return "Not Set";
      },
    },
    {
      title: "END DATE",
      dataIndex: "end_time",
      render: (data) => {
        if (data) return <EndDate end={data} />;
        return "Not Set";
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (data) => <TaskStatus status={data} />,
    },
    {
      title: "ACTION",
      dataIndex: "project",
      align: "center",
      render: (data) => (
        <div className="flex justify-center">
          <FaEye
            className="cursor-pointer mr-3"
            onClick={() => navigate(`/task/${data?.slug}`)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-abel mx-2 overflow-x-scroll">
      <Table dataSource={tasks} columns={columns} key="slug" rowKey="slug" />
    </div>
  );
};

export default AvailableTasks;
