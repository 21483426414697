import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IKpiResponse, IKpiStatus } from "../types/kpi";

const initialState: IKpiStatus = {
  kpis: [],
  current_page: 1,
  last_page: 1,
  loading: false,
};

export const kpi = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    setKpis: (state, action: PayloadAction<IKpiResponse>) => {
      state.last_page = action.payload.current_page;
      state.current_page = action.payload.last_page;
      state.kpis = action.payload.data;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
  },
});

export const { setKpis, setLoading, setShow } = kpi.actions;

export default kpi.reducer;
