// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and dispatcher
import { AppDispatch } from "../../store";
import { ICreateUser, IUserUpdateAccess } from "../../store/types/user";
import { setUsers, setLoading, setUser } from "../../store/slices/user";

// notification
import { openNotification } from "../../components/ui/alerts";
import { NavigateFunction } from "react-router-dom";

export const get_users = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/organization-user");

    dispatch(setUsers(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_user = async (dispatch: AppDispatch, id: string | number) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/organization-user/${id}`);

    dispatch(setUser(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const create = async (
  dispatch: AppDispatch,
  data: ICreateUser,
  navigation: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/organization-user", data);

    openNotification({
      status: "success",
      message: response.data?.message,
    });
    navigation(-1);
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const update = async (
  dispatch: AppDispatch,
  data: IUserUpdateAccess
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch("/organization-user", data);

    dispatch(setUser(response.data?.data));
    openNotification({
      status: "success",
      message: response.data?.message,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const destroy = async (dispatch: AppDispatch, data: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/organization-user/${data}`);

    console.log(response.data);
    // dispatch(setUser(response.data?.data));
    // openNotification({
    //   status: "success",
    //   message: response.data?.message,
    // });
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};
