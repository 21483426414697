import React from "react";
import useNotification from "../hooks/useNotification";

// components
import { Modal } from "antd";
// import TaskStatus from "../../../components/ui/status/TaskStatus";
import Button from "../../../components/elements/button/Button";

// formate and constants
import { GoZap } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { update_notification } from "../../../services/api/setting";
import { NotificationStatus } from "../../../store/types/setting";
import { format_date_time } from "../../../services/date/format";
import { useNavigate } from "react-router-dom";

type IShowNotification = {
  slug: string;
  open: boolean;
  onClose: () => void;
};

const ShowNotification: React.FC<IShowNotification> = ({
  open,
  slug,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(({ setting }) => setting.notification);
  const statuses = useAppSelector(({ misc }) => misc.notification_statuses);
  const loading = useAppSelector(({ setting }) => setting.loading);
  const { data, dataSet } = useNotification(slug);

  const navigate = useNavigate();

  return (
    <Modal
      onCancel={onClose}
      open={open}
      footer={[]}
      confirmLoading={loading}
      centered
      closable={false}
      width=""
      closeIcon={false}
    >
      <div className="h-full w-full font-abel">
        <div className="my-2 flex items-center justify-between ">
          <p className="capitalize font-bold text-[20px] flex justify-center items-center">
            {notification?.task?.name}
            {NotificationStatus.SORTED === Number(notification?.seen) && (
              <IoCheckmarkDoneCircleSharp className="mx-2" color="#099137" />
            )}
          </p>
          <div className="">
            <GrClose size={20} onClick={onClose} className="cursor-pointer" />
          </div>
        </div>

        <div className="my-3 w-full grid gap-3 grid-cols-1 md:grid-cols-3 pb-3">
          <div className="mr-2 col-span-1">
            <h1>
              <span className="font-[600]">Project Name:</span>{" "}
              {notification?.task?.project?.name}
            </h1>
            <p className="capitalize">
              <span className="font-[600]">Assigned:</span>
              {` ${notification?.task?.assigned?.profile?.first_name || ""} ${
                notification?.task?.assigned?.profile?.last_name || ""
              }`}
            </p>
            <p className="mb-2">
              <span className="font-[600]">Deadline:</span>{" "}
              <span className="bg-[#E7F6EC] text-[#099137] w-min py-[2px] px-2 rounded-10">
                {notification?.task?.deadline?.name}
              </span>
            </p>

            <div className="border-b mb-2"></div>

            <p>
              <span className="font-[600]">Start time:</span>{" "}
              {notification?.task?.start_time &&
                format_date_time(notification?.task?.start_time)}
            </p>
            <p className="mb-2">
              <span className="font-[600]">End time:</span>{" "}
              {notification?.task?.start_time &&
                format_date_time(notification?.task?.end_time)}
            </p>

            <div className="border-b mb-2"></div>

            <p>
              <span className="font-[600]">Created:</span>
              <span className="bg-primary text-[#fff] w-min mx-2 py-[2px] px-[2px] rounded-[5px]">
                {notification?.created_at &&
                  format_date_time(notification?.created_at)}
              </span>
            </p>

            <p>
              <span className="font-[600]">Updated:</span>
              <span className="bg-[#099137] text-[#fff] w-min mx-2 py-[2px] px-[2px] rounded-[5px]">
                {notification?.updated_at &&
                  format_date_time(notification?.updated_at)}
              </span>
            </p>
          </div>

          <div className="col-span-2 border rounded-6 px-1 py-2">
            <span className="font-[600]">Project Description:</span>{" "}
            <p>{notification?.task?.description}</p>
            <hr className="my-2" />
            <span className="font-[600]">Task Description:</span>
            <p>
              {notification?.task?.project &&
                notification?.task?.project?.description}
            </p>
          </div>
        </div>

        {NotificationStatus.UNSORTED === Number(notification?.seen) && (
          <div className="flex items-center my-2">
            <div className="w-[47%] md:w-[32%]">
              <select
                name="status_id"
                value={data.status_id}
                className="w-full border py-2 px-1 rounded-6"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  dataSet((prev) => ({
                    ...prev,
                    status_id: Number(e.target.value),
                  }))
                }
              >
                <option className="capitalize">Select One</option>
                {statuses?.map((item, index) => (
                  <option
                    className="capitalize"
                    value={item?.id}
                    key={index + 1}
                  >
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <Button
                title="Confirm"
                icon={<GoZap />}
                onClick={() => {
                  if (data.slug && data.status_id)
                    update_notification(dispatch, data, navigate);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ShowNotification;
