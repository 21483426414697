import React from "react";
import {
  get_notification,
  get_status_notifications,
} from "../../../services/api/setting";
import { useAppDispatch } from "../../../store/hooks";
import { INotificationUpdate } from "../../../store/types/setting";

const useNotification = (slug: string) => {
  const dispatch = useAppDispatch();
  const [data, dataSet] = React.useState<INotificationUpdate>({
    slug: "",
    status_id: 1,
  });

  const onLoad = React.useCallback(() => {
    if (slug) {
      get_status_notifications(dispatch);
      get_notification(dispatch, slug);
      dataSet((prev) => ({ ...prev, slug }));
    }
  }, [dispatch, slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return { data, dataSet };
};

export default useNotification;
