import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import {
  IAuthResponse,
  IAuthState,
  IOrganizationResponse,
  IUser,
} from "../types/auth";

const initialState: IAuthState = {
  user: null,
  verified: false,
  admin: false,
  organization: null,
  loading: false,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthResponse: (state, action: PayloadAction<IAuthResponse>) => {
      state.verified = action.payload.verified;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setOrganization: (state, action: PayloadAction<IOrganizationResponse>) => {
      state.admin = action.payload.admin;
      state.organization = action.payload.organization;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setAuthResponse, setUser, setOrganization, setLoading } =
  auth.actions;

export default auth.reducer;
