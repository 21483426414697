import React from 'react';

type ITextInput = {
    name: string;
    placeholder: string;
    value: string | number | [];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: React.ReactNode;
};

const SearchInput: React.FC<ITextInput> = ({
    placeholder,
    name,
    icon,
    value,
    onChange
}) => {
    return (
        <div className="w-full flex border border-[#F0F2F5] rounded-sm p-[4px] bg-[#F0F2F5] hover:border-[#243677] h-[40px]">
            {icon && <div className="py-2 pl-3 pr-1">{icon}</div>}
            <input
                name={name}
                id={name}
                type="text"
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                className="px-2 outline-none flex-1 border-[#243677] bg-[#F0F2F5] font-semibold text-14"
            />
        </div>
    );
};

export default SearchInput;
