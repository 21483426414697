import React from "react";
// import { get_week_past } from "../../../services/date/format";

// types and time
import { get_task_days_percent } from "../../../services/date/format";

type ITimeFrame = {
  start_time: Date;
  end_time: Date;
};

const TimeLeft: React.FC<ITimeFrame> = ({ start_time, end_time }) => {
  return (
    <p className="text-[#344054]">
      {`${get_task_days_percent(start_time, end_time)}%`}
    </p>
  );
};

export default TimeLeft;
