import React from "react";

// types
import { format_date_time } from "../../../services/date/format";

type IEndDate = {
  end_time: Date;
};

const EndDate: React.FC<IEndDate> = ({ end_time }) => {
  return (
    <div>{end_time ? format_date_time(end_time) : "Not a proper date"}</div>
  );
};

export default EndDate;
