import React from "react";
import { Progress } from "antd";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { percentage_calculator } from "../../../utility/calculator";

const ProgressBar: React.FC = () => {
  const total = useAppSelector(({ task }) => task.total);
  const completed = useAppSelector(({ task }) => task.completed_task);
  const [percent, percentSet] = React.useState<number>(0);

  const onLoad = React.useCallback(() => {
    if (total && (completed || completed === 0))
      percentSet(percentage_calculator(total, completed));
  }, [completed, total]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  // let see if it is fixed

  return (
    <div className="flex items-center justify-between w-full mx-2 mt-6">
      <div className="bg-white rounded-10 border border-[#E4E7EC] py-3 px-4 my-2 w-[80%] max-w-[800px]">
        <p className="text-16 font-bold">
          Total task completion for the second quarter
        </p>
        <Progress percent={percent} strokeColor={"#40B869"} showInfo={false} />
        <p className="text-[#98A2B3] text-14">
          {completed} of {total} schedules |
          <span className="text-[#567BFF] pl-1">
            {percent.toFixed(0)}% Completed
          </span>
        </p>
      </div>
      <Link
        to="/create-task"
        className="flex items-center justify-between bg-primary text-white hover:bg-black hover:text-white decoration-white rounded-8 text-14 font-bold h-[36px] w-[130px] px-3 ml-4 mr-3"
      >
        <FiPlusCircle size={20} />{" "}
        <span className="text-[9px] md:text-[14px]">Create Project</span>
      </Link>
    </div>
  );
};

export default ProgressBar;
