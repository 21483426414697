import { IUser } from "./auth";
import { ILoadingState } from "./misc";
import { IProject } from "./project";
// import { IProject } from "./project";
import { ITask } from "./task";

export type ISettingStatus = {
  notifications: INotification[];
  notification: INotification | null;
  notification_current: number;
  notification_last: number;
} & ILoadingState;

export type INotificationResponse = {
  current_page: number;
  last_page: number;
  data: INotification[];
};

export type INotification = {
  created_at: Date;
  seen: number;
  slug: string;
  viewed_by: IUser | null;
  seen_by: IUser;
  updated_at: Date;
  task: ITask & {
    deadline: { id: number; name: string };
    verified_by: string;
    project?: IProject;
  };
};

export type INotificationUpdate = {
  slug: string;
  status_id: number;
};

export enum NotificationStatus {
  UNSORTED = 1,
  SORTED = 2,
}
