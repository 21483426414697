import React from "react";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import useChangePassword from "./hooks";
import TextInput from "../../../components/elements/forms/TextInput";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { change_password } from "../../../services/api/auth";
import db from "../../../constants/database";
import { Link, useNavigate } from "react-router-dom";
import ChangePasswordToken from "../misc/ChangePasswordToken";

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(({ auth }) => auth.loading);
  const { err, errSet, data, dataSet, visible, visibleSet, onValidate } =
    useChangePassword();

  const onSubmitHandler = async () => {
    onValidate();
  };

  const onLoad = React.useCallback(() => {
    const email = localStorage.getItem("@email");
    if (!email) {
      navigate("/forgot-password");
    } else {
      dataSet((prev) => ({ ...prev, email }));
    }
  }, [dataSet, navigate]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <div className="bg-[#FAFBFF] font-abel flex justify-center items-center h-screen px-4 py-4">
      <div className="max-w-456 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-8 px-7 w-full">
          <Link to="/login" className="flex justify-center mb-2">
            <img src={db.logo} alt="logo" className="w-[120px]" />
          </Link>
          <h1 className="text-[28px] text-center">Create New Password</h1>
          <p className="text-[#667185] text-16 text-center mb-5">
            Enter your new password to access your account
          </p>
          <p className="text-lg font-[700] text-[#000000] text-opacity-60">
            {data.email}
          </p>
          <TextInput
            label="PASSWORD"
            placeholder="Enter Password"
            status={err.password}
            name="password"
            type="password"
            value={data.password}
            onChange={onChange}
            container="my-3"
          />
          <TextInput
            label="CONFIRM PASSWORD"
            placeholder="Confirm Password"
            status={err.password_confirmation}
            name="password"
            type="password"
            value={data.password_confirmation}
            onChange={onChange}
            container="my-3"
          />
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Change Password"
                loading={loading}
                onSubmitHandler={onSubmitHandler}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordToken
        visible={visible}
        onCancel={async (res) => {
          if (res) {
            dataSet((prev) => ({ ...prev, token: res }));
            change_password(dispatch, { ...data, token: res }, navigate);
          }
          visibleSet(false);
        }}
      />
    </div>
  );
};

export default ChangePassword;
