// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import { openNotification } from "../../components/ui/alerts";

// types and dispatcher and router
import { AppDispatch } from "../../store";
import {
  createTaskDeadline,
  setLoading,
} from "../../store/slices/task_deadline";
import { ITaskDeadlineData } from "../../store/types/task_deadline";

export const get_tasks = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.get(`/tasks/${slug}`);
    // dispatch(setTasks(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_task = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.get(`/task/${slug}`);
    // dispatch(setTasks(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const create = async (
  dispatch: AppDispatch,
  data: ITaskDeadlineData
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/task-deadline", data);

    dispatch(createTaskDeadline(response.data?.data));
    openNotification({ status: "success", message: response?.data?.message });
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "success",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};
