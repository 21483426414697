import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
// import useForgotPassword from "./hooks";
import TextInput from "../../../components/elements/forms/TextInput";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import db from "../../../constants/database";
import { ErrorWarning } from "../../../store/types/misc";
import { forgot_password } from "../../../services/api/auth";

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(({ auth }) => auth.loading);

  const [email, emailSet] = React.useState<string>("");
  const [err, errSet] = React.useState<ErrorWarning>("");

  const onSubmitHandler = async () => {
    const response = await forgot_password(dispatch, { email });
    if (response) {
      navigate("/change-password");
    }
  };
  // const loading = useAppSelector(({ auth }) => auth.loading);
  // const { data, error, onChangeHandler, onValidate } = useForgotPassword();

  return (
    <div className="bg-[#FAFBFF] font-abel flex justify-center items-center h-screen px-4 py-4">
      <div className="max-w-456 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-8 px-7 w-full">
          <Link to="/login" className="flex justify-center mb-2">
            <img src={db.logo} alt="logo" className="w-[120px]" />
          </Link>
          <h1 className="text-[28px] text-center">Forgot Password</h1>
          <p className="text-[#667185] text-16 text-center mb-5">
            Enter your email to reset your account
          </p>
          <TextInput
            label="EMAIL ADDRESS"
            placeholder="Enter Email"
            status={err}
            name="email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              emailSet(e.target.value);
              errSet("");
            }}
          />
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Reset Account"
                onSubmitHandler={onSubmitHandler}
                loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>

          <div className="text-center mt-6">
            <span>
              Remember your password?{" "}
              <Link to="/login" className="text-[#243677]">
                {" "}
                Login
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
