import React from "react";

// types and services
import { useAppDispatch } from "../../../store/hooks";

// components
import OrganizationUser from "../misc/OrganizationUser";
import Button from "../../../components/elements/button/Button";

// constants and constants
import { FiPlusCircle } from "react-icons/fi";

// router
import { useNavigate } from "react-router-dom";
import { get_organization } from "../../../services/api/auth";
import { get_users } from "../../../services/api/project_user";

const UserDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLoad = React.useCallback(() => {
    get_organization(dispatch);
    get_users(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="px-4 md:px-5 pb-5 w-full">
      <div className="flex items-center justify-between w-full py-3 mx-2 mb-2">
        <p className="text-16 font-bold text-[#100F0F] py-2 pl-12 md:pl-0">
          User Management
        </p>

        <Button
          title="Create User"
          onClick={() => navigate("/create-user")}
          icon={<FiPlusCircle size={20} />}
        />
      </div>

      <OrganizationUser />
    </div>
  );
};

export default UserDashboard;
