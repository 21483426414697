import axios, {
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from "axios";

// config
import env from "../config/env";
import { delete_cookie, get_cookie } from "./cookies";

const headers = {};

const axiosInstance = axios.create({
  baseURL: env.baseURL,
  headers,
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const serial = get_cookie("@serial");
    if (serial) {
      config.headers.Authorization = `bearer ${serial}`;
    }
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error: Promise<AxiosError>) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: any): Promise<AxiosResponse> => {
    return response;
  },
  (error: AxiosError): Promise<AxiosError> => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      delete_cookie("@serial");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
