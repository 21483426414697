import React from "react";

// types
import { format_date_time } from "../../../services/date/format";

type IStartDate = {
  start: Date;
};

const StartDate: React.FC<IStartDate> = ({ start }) => {
  return <div>{start ? format_date_time(start) : "Not a proper date"}</div>;
};

export default StartDate;
