import GitInfo from "react-git-info/macro";

const gitInfo = GitInfo();

const test = {
  baseURL: process.env.REACT_APP_DEVELOPMENT_TEST,
  crypt: process.env.REACT_APP_COOKIE_ENCRYPT,
  enviroment: process.env.NODE_ENV,
};

const development = {
  baseURL: process.env.REACT_APP_DEVELOPMENT_BASE_URL,
  crypt: process.env.REACT_APP_COOKIE_ENCRYPT,
  enviroment: process.env.NODE_ENV,
};

const production = {
  baseURL: process.env.REACT_APP_PRODUCTION_BASE_URL,
  crypt: process.env.REACT_APP_COOKIE_ENCRYPT,
  enviroment: process.env.NODE_ENV,
};

export default gitInfo.branch === "main"
  ? production
  : gitInfo.branch === "main-test"
  ? development
  : test;
