// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import { openNotification } from "../../components/ui/alerts";

// types and dispatcher
import { AppDispatch } from "../../store";
import {
  setLoading,
  setProject,
  setTotal,
  setShow,
  setUpdate,
  setProjects,
  setDelete,
} from "../../store/slices/project";
import { ICreateProject, IUpdateProject } from "../../store/types/project";
import { NavigateFunction } from "react-router-dom";

export const get_projects = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/projects-user");

    dispatch(setProjects(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_project = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setShow());
  try {
    const response = await axiosInstance.get(`/project/${slug}`);

    dispatch(setProject(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setShow());
};

export const create = async (
  dispatch: AppDispatch,
  data: ICreateProject,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/project", data);

    openNotification({ status: "success", message: response.data?.message });
    navigate("/tasks");
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const update = async (dispatch: AppDispatch, data: IUpdateProject) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch("/project", data);

    dispatch(setProject(response.data?.data));
    openNotification({
      status: "success",
      message: response.data?.message,
    });
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setUpdate());
};

export const destroy = async (dispatch: AppDispatch, slug: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/project/${slug}`);

    dispatch(setDelete(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
  dispatch(setLoading());
};

export const get_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete("/project");

    console.log(response.data);
    // dispatch(setTotal(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

/**
 *
 * Admin
 *
 */

export const get_total_project = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/project-total");

    dispatch(setTotal(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};
