import React from "react";

// types and services
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { assing_task } from "../../../services/api/task";

// components
import { Modal } from "antd";
import TextInput from "../../../components/elements/forms/TextInput";
import { get_users } from "../../../services/api/project_user";

export type IAssingUser = {
  open: boolean;
  slug: string;
  onClose: () => void;
};

const AssingUser: React.FC<IAssingUser> = ({ open, slug, onClose }) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(({ user }) => user.users)?.map(
    (item) => item.user
  );
  const loading = useAppSelector(({ task }) => task.loading);

  const [email, emailSet] = React.useState<string>("");

  const onLoad = React.useCallback(() => {
    get_users(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      title="Update Status"
      open={open}
      onOk={() => {
        if (slug && email) assing_task(dispatch, { email, slug });
      }}
      onCancel={onClose}
      confirmLoading={loading}
      centered={true}
    >
      <TextInput
        status=""
        placeholder="Enter Email"
        value={email}
        name="email"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          emailSet(e.target.value);
        }}
      />
      <div>
        {email.length ? (
          users
            .filter((item) => item.email.includes(email))
            .map((item) => (
              <div
                onClick={() => emailSet(item.email)}
                className="cursor-pointer"
              >
                {item.email}
              </div>
            ))
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default AssingUser;
