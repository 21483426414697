import React from "react";

// components
import ListSubTasks from "../misc/ListSubTasks";

// types and service
import { FiArrowLeft, FiPlusCircle } from "react-icons/fi";

// router
import { Link, useNavigate } from "react-router-dom";

// types, hooks and service
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_project } from "../../../services/api/project";
import { useParams } from "react-router-dom";
import { get_tasks } from "../../../services/api/task";
import Button from "../../../components/elements/button/Button";

const ShowDetial: React.FC = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  const project = useAppSelector(({ project }) => project.project);
  const admin = useAppSelector(({ auth }) => auth.admin);

  const onLoad = React.useCallback(() => {
    if (slug) {
      get_project(dispatch, slug);
      get_tasks(dispatch, slug);
    }
  }, [dispatch, slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel w-full h-[90%]">
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[18px] font-semibold px-6">
        <p className="font-bold">Tasks</p>
        <div className="flex">
          <Button
            title="Create Task"
            onClick={() => navigate(`/create-subtask/${slug}`)}
            icon={<FiPlusCircle size={20} />}
            className="w-[120px]"
          />
          <Link to="/tasks" className="flex items-center">
            <FiArrowLeft size={25} />{" "}
            <span className="ml-2 underline">Go back</span>
          </Link>
        </div>
      </div>
      <div className="flex justify-between items-center h-[56px] bg-[#F2F5FF] text-primary text-[15px] font-semibold px-6">
        <div>
          <p className="capitalize border-b border-primary">{project?.name}</p>
          <p>{project?.description}</p>
        </div>

        <div>
          <p className="capitalize border-b border-primary">
            {`Supervisor: ${project?.supervisor?.profile?.first_name} ${project?.supervisor?.profile?.last_name}`}
          </p>
          <p>Email: {project?.supervisor?.email}</p>
        </div>

        {admin ? (
          <div>
            <p className="capitalize border-b border-primary">
              {`Created By: ${project?.created_by?.profile?.first_name} ${project?.created_by?.profile?.last_name}`}
            </p>
            <p>Email: {project?.created_by?.email}</p>
          </div>
        ) : null}
      </div>

      <ListSubTasks />
    </div>
  );
};

export default ShowDetial;
