import React from "react";
import useUpdateTask from "../hooks/useUpdateTask";
import TextInput from "../../../components/elements/forms/TextInput";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import TextInputBox from "../../../components/elements/forms/TextInputBox";

// service and types
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_project, update } from "../../../services/api/project";
import { useParams } from "react-router-dom";

const UpdateTask: React.FC = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const loading = useAppSelector(({ project }) => project.update);

  const { data, error, on_change, on_change_text, on_validate } =
    useUpdateTask();

  const onLoad = React.useCallback(() => {
    if (slug) get_project(dispatch, slug);
  }, [dispatch, slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel flex justify-center items-center h-[90%] px-4 py-4">
      <div className="max-w-456 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-7 px-7 w-full">
          <h1 className="text-24 font-bold text-black text-center">
            Update Task
          </h1>
          <TextInput
            label="Task Name"
            placeholder="Enter Subject"
            status={error.name}
            name="name"
            value={data.name}
            onChange={on_change}
            container="mb-4"
          />
          {/* <TextInput
            label="Task Owner(s)"
            placeholder="Enter Owner"
            status={""}
            name="text"
            value={""}
            onChange={on_change}
          /> */}
          <TextInputBox
            label="Task Details"
            placeholder="Enter text here..."
            status={error.description}
            name="description"
            value={data.description}
            onChange={on_change_text}
          />
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Update Task"
                onSubmitHandler={() => {
                  if (on_validate()) update(dispatch, data);
                }}
                loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTask;
