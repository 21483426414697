import { calculate_percentage } from "./ratio";

export const format_date_time = (timestamp: Date) => {
  return new Date(timestamp).toString().substring(4, 21);
};

export const get_week_past = (end_date: Date) => {
  const date = new Date(end_date).getTime();
  const today = new Date().getTime();
  const weeks = Math.floor((date - today) / (1000 * 60 * 60 * 24 * 7));

  if (weeks > 0) return `${weeks} weeks(s)`;
  else return get_days_past(end_date);
};

export const get_days_past = (created_at: Date) => {
  const date = new Date(created_at).getTime();
  const today = new Date().getTime();

  const days = Math.floor((date - today) / (1000 * 60 * 60 * 24));
  if (days > 1) return `${days} day(s)`;
  return "Expired";
};

export const get_task_days_percent = (start: Date, end: Date) => {
  const start_date = new Date(start).getTime();
  const end_date = new Date(end).getTime();
  const now = new Date().getTime();

  // Task not begin
  if (start_date > now) return 100;

  // Start has started
  if (now > end_date) return 0;

  return calculate_percentage(now - start_date, end_date - start_date);
};
