// import { IUser } from "./auth";
import { IUser } from "./auth";
import { ILoadingState, ICurrentType, ErrorWarning } from "./misc";
import { IProject } from "./project";

export type ITaskState = {
  total: number;
  completed_task: number;
  failed_task: number;
  tasks: ITask[];
  task: null;
} & ILoadingState &
  ICurrentType;

export type ITaskResponse = {
  current_page: number;
  last_page: number;
  data: ITask[];
};

export type ITask = {
  completed_at: null | Date;
  description: string;
  created_at: Date;
  end_time: Date;
  name: string;
  project: { name: string; slug: string; project?: IProject };
  slug: string;
  start_time: Date;
  status: { id: number; name: string };
  verified_by: null | IUser;
  assigned: IUser;
  seen_by: IUser;
  viewed_by: IUser;
};

export type IDeadline = {
  created_at: Date;
  end_time: Date;
  point: number;
  start_time: Date;
  status: number;
};

export type ITaskStatus = {
  id: number;
  name: string;
};

export enum ITaskStatuses {
  OPEN = 1,
  STARTED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
}

export type ICreateTask = {
  project_slug: string;
  name: string;
  organization_slug: string;
  start_time: string;
  end_time: string;
  email: string;
  description: string;
};

export type ICreateTaskError = {
  project_slug: ErrorWarning;
  name: ErrorWarning;
  organization_slug: ErrorWarning;
  start_time: ErrorWarning;
  end_time: ErrorWarning;
  email: ErrorWarning;
  description: ErrorWarning;
};

export type IUpdateStatus = {
  status_id: number | null;
  organization_slug: string;
  slug: string;
};

export type IAssignUser = {
  email: string;
  slug: string;
};
