import React from "react";

// components
import SearchInput from "../../components/elements/forms/SearchInput";

// icons
import { BiSearchAlt } from "react-icons/bi";
import { get_organization } from "../../services/api/auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector(({ auth }) => auth.admin);
  const organization = useAppSelector(({ auth }) => auth.organization);

  const onLoad = React.useCallback(() => {
    get_organization(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <nav className="font-abel flex items-center justify-between px-5 py-4 border-b border-[#E4E7EC] bg-white text-[#243677]">
      <div className="w-[50%] mx-2">
        <SearchInput
          name="search"
          value=""
          placeholder="Search here"
          icon={<BiSearchAlt size={18} color="#243677" />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   const { value } = e.target;
          }}
        />
      </div>

      <div className="flex">
        <h2 className="capitalize text-[12px] md:text-lg font-bold md:mr-5 mr-3">
          {organization?.name}
        </h2>
        <h2 className="capitalize text-[12px] md:text-lg font-bold">
          {admin ? "admin" : ""}
        </h2>
      </div>
    </nav>
  );
};

export default Navbar;
