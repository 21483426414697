import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { ITaskDeadline, ITaskDeadlineStatus } from "../types/task_deadline";

const initialState: ITaskDeadlineStatus = {
  deadlines: [],
  loading: false,
};

export const task_deadline = createSlice({
  name: "task_deadline",
  initialState,
  reducers: {
    setTaskDeadlines: (state, action: PayloadAction<ITaskDeadline[]>) => {
      state.deadlines = action.payload;
    },
    createTaskDeadline: (state, action: PayloadAction<ITaskDeadline>) => {
      state.deadlines = [...state.deadlines, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

export const {
  setTaskDeadlines,
  createTaskDeadline,
  setLoading,
  setShow,
  setUpdate,
} = task_deadline.actions;

export default task_deadline.reducer;
