import React from "react";
import useSubTask from "../hooks/useSubTask";

// components
import TextInput from "../../../components/elements/forms/TextInput";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import TextInputBox from "../../../components/elements/forms/TextInputBox";

// types and services
import { get_organization } from "../../../services/api/auth";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { create } from "../../../services/api/task";
import { useNavigate } from "react-router-dom";

const CreateSubTask: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(({ task }) => task.loading);
  const { data, error, onChange, onChangeDate, onChangeText, onValidate } =
    useSubTask();

  const onLoad = React.useCallback(() => {
    get_organization(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel flex justify-center items-center h-[90%] px-4 py-4">
      <div className="max-w-950 w-full bg-[white] border border-[#D0D5DD] rounded-10">
        <div className="py-7 px-7 w-full">
          <h1 className="text-24 font-bold text-black text-center">
            Create Task
          </h1>
          <TextInput
            label="Task Name"
            placeholder="Enter Subject"
            status={error.name}
            name="name"
            value={data.name}
            onChange={onChange}
            container="mb-4"
          />
          <TextInput
            label="Task Owner(s)"
            placeholder="Enter Owner"
            status={error.email}
            name="email"
            value={data.email}
            onChange={onChange}
            container="mb-4"
          />
          {/* <TextInput
            label="Task Supervisor"
            placeholder="Enter Subject"
            status={""}
            name="text"
            value={""}
            // onChange={onChangeHandler}
            container="mb-4"
          /> */}
          <div className="flex md:justify-between flex-wrap">
            <TextInput
              label="Start Date"
              placeholder="Enter Subject"
              status={error.start_time}
              name="start_time"
              value={data.start_time}
              type="datetime-local"
              onChange={onChangeDate}
              container="mb-4 md:w-[49%] w-full"
            />
            <TextInput
              label="End Date"
              placeholder="Enter Subject"
              status={error.end_time}
              name="end_time"
              value={data.end_time}
              type="datetime-local"
              onChange={onChangeDate}
              container="mb-4 md:w-[49%] w-full"
            />
          </div>
          {/* <div className="flex md:justify-between flex-wrap">
            <TextInput
              label="Start Time"
              placeholder="Enter Subject"
              status={""}
              name="text"
              value={""}
              type="time"
              // onChange={onChangeHandler}
              container="mb-4 md:w-[49%] w-full"
            />
            <TextInput
              label="End Time"
              placeholder="Enter Subject"
              status={""}
              name="text"
              value={""}
              type="time"
              // onChange={onChangeHandler}
              container="mb-4 md:w-[49%] w-full"
            />
          </div> */}
          <TextInputBox
            label="Task Details"
            placeholder="Enter text here..."
            status={""}
            name="description"
            value={data.description}
            onChange={onChangeText}
          />
          <div className="flex items-center mt-7 align-center">
            <div className="w-[100%]">
              <SubmitButton
                title="Create Task"
                onSubmitHandler={() => {
                  if (onValidate() === true) create(dispatch, data, navigate);
                }}
                loading={loading}
                className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSubTask;
