import React from "react";

// components
import EndDate from "./EndDate";
import StartDate from "./StartDate";
import type { TableProps } from "antd";
import { Popconfirm, Table } from "antd";
import TimeLeft from "../../../components/ui/status/TimeLeft";
import TaskStatus from "../../../components/ui/status/TaskStatus";

// react router
import { useNavigate } from "react-router-dom";

// cointants and icons
import { FaEye, FaTrashCan } from "react-icons/fa6";

// types and service
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { destroy } from "../../../services/api/task";
import { ITask } from "../../../store/types/task";

const ListSubTask: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tasks = useAppSelector(({ task }) => task.tasks);

  const columns: TableProps<ITask>["columns"] = [
    {
      title: "TASKS",
      align: "center",
      render: (data) => (
        <span
          onClick={() => navigate(`/task/${data?.slug}`)}
          className="capitalize cursor-pointer"
        >
          {data?.name}
        </span>
      ),
    },
    {
      title: "Project",
      dataIndex: "project",
      align: "center",
      render: (data) => {
        return <span>{data?.name}</span>;
      },
    },
    {
      title: "TASK OWNER",
      dataIndex: "assigned",
      align: "center",
      render: (data) => {
        if (data?.profile) {
          return (
            <span>{`${data?.profile?.first_name} ${data?.profile?.last_name}`}</span>
          );
        }
        return <span>Not Assigned</span>;
      },
    },
    {
      title: "TIME LEFT",
      align: "center",
      render: (data) => (
        <TimeLeft start_time={data?.start_time} end_time={data?.end_time} />
      ),
    },
    {
      title: "START DATE",
      align: "center",
      render: (data) => {
        if (data) return <StartDate start={data?.start_time} />;
      },
    },
    {
      title: "END DATE",
      align: "center",
      render: (data) => {
        if (data) return <EndDate end={data?.end_time} />;
      },
    },
    {
      title: "STATUS",
      align: "center",
      dataIndex: "status",
      render: (data) => <TaskStatus status={data} />,
    },
    {
      title: "ACTION",
      // dataIndex: "project",
      align: "center",
      render: (data) => (
        <div className="flex justify-center items-center">
          <FaEye
            className="cursor-pointer mr-3"
            onClick={() => navigate(`/task/${data?.slug}`)}
          />
          {Number(data.status?.id) === 1 && (
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              placement="leftBottom"
              onConfirm={() => {
                console.log(data);
                if (data?.slug) destroy(dispatch, data?.slug);
              }}
              onCancel={() => null}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FaTrashCan className="cursor-pointer" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-abel mx-2 pl-12 md:pr-4 md:pl-4 overflow-x-scroll">
      <Table dataSource={tasks} columns={columns} key="slug" rowKey="slug" />
    </div>
  );
};

export default ListSubTask;
